import { useTranslation } from 'react-i18next'
import { InlineWidget } from 'react-calendly'

import { usePersonalInfoDataState } from '../_personalInfoDataState'

import FormFooter from '../../shared/FormFooter'

const TenThirtyOneComplete = () => {
  const { t } = useTranslation()
  const { data: personalData } = usePersonalInfoDataState()

  return (
    <div className="h-full w-full border-t-4 border-success bg-white p-6 pb-20">
      <h1 className="mb-6 text-2xl font-bold text-content-black">
        <i
          className="fa-solid fa-circle-check mr-3"
          style={{ color: '#01B6A1' }}
        ></i>
        We are reviewing your 1031 transaction
      </h1>
      <p className="mb-6 text-16 text-content-black">
        <span className="font-medium">{personalData?.first_name}</span>, thank
        you for your investment request. Our team will reach out to you with
        next steps shortly. {t('completed:questionsCopy')}{' '}
        <a className="text-primary" href="tel:+14243204205">
          424-320-4205
        </a>{' '}
        or email us at{' '}
        <a className="text-primary" href="mailto:investor-help@realtymogul.com">
          investor-help@realtymogul.com
        </a>
        .
      </p>
      <div className="mb-6">
        <FormFooter
          submitFunc={() => (window.location.href = '/dashboard')}
          secondaryLink
          buttonText="Go to Dashboard"
        />
      </div>

      <p className="mb-4 text-[19px] font-medium">Schedule a call with us</p>
      <p className="mb-6 text-16 text-content-black">
        If you'd like to talk more about your 1031 transaction or have more
        detailed questions, please schedule a call with our 1031 exchange
        specialist using the calendar below.
      </p>
      <InlineWidget
        url="https://calendly.com/realtymogul-investor-relations/1031"
        pageSettings={{
          primaryColor: '1d89ff',
          textColor: '202020',
        }}
        styles={{
          height: '800px',
        }}
      />
    </div>
  )
}

export default TenThirtyOneComplete
