import { useTranslation } from 'react-i18next'

const EmployedFailedScreen = () => {
  const { t } = useTranslation()

  return (
    <div className="h-full w-full bg-white">
      <div className="flex gap-[72px]">
        <div className="w-full border-t-4 border-alert-light p-6">
          <p className="mb-6 text-16 font-medium text-content-black">
            {t('failed:employmentHeader')}
          </p>
          <p className="mb-12 text-base text-content-black">
            {t('failed:employment1')}{' '}
            <a
              className="text-primary"
              href="mailto:investor-help@realtymogul.com"
            >
              investor-help@realtymogul.com
            </a>
            .
          </p>
          <a
            className="flex h-10 w-full md:w-fit px-4 items-center justify-center rounded bg-primary-hover text-16 font-medium hover:bg-primary border-bg-primary text-[#F2F2F2] disabled:opacity-50"
            data-cy="continue-button"
            href="/dashboard"
          >
            {t('common:seeOtherOpps')}
          </a>
        </div>
        <div className="w-[400px]"></div>
      </div>
    </div>
  )
}

export default EmployedFailedScreen
