import { useQuery } from '@tanstack/react-query'
import { datadogRum } from '@datadog/browser-rum'
import { getUser, fetchDevices } from './_auth'
import { identify } from '../../utils/analytics'
import { CustomCognitoUser } from './_types'

declare global {
  interface Window {
    DD_RUM: any
  }
}

export const useAuthDataState = () => {
  return useQuery({
    queryKey: ['auth'],
    queryFn: getUser,
    onSuccess: (data: CustomCognitoUser) => {
      identify(data.attributes?.sub, data.attributes)
      datadogRum.setUser({
        name: data.attributes.given_name + ' ' + data.attributes.family_name,
      })
    },
    refetchOnWindowFocus: false,
    staleTime: 30000,
    retry: false,
  })
}

export const useAuthDevicesState = () => {
  return useQuery({
    queryKey: ['devices'],
    queryFn: fetchDevices,
    refetchOnWindowFocus: true,
    staleTime: 30000,
    retry: false,
  })
}
