import { useState } from 'react'
import { EnterPhone } from './sms-enter-phone'
import { VerifyPhone } from './sms-verify'
import { ProfileHeader } from '../../profile-header'

export const SMSSetup = () => {
  const [screen, setScreen] = useState<'enter-phone' | 'verify-phone'>(
    'enter-phone'
  )
  return (
    <>
      <div>
        <ProfileHeader
          subnav="Multi-Factor Authentication"
          title="Verify your phone number"
        />
        {screen === 'enter-phone' && <EnterPhone setScreen={setScreen} />}
        {screen === 'verify-phone' && <VerifyPhone />}
      </div>
    </>
  )
}

export default SMSSetup
