import { Link, useNavigate } from 'react-router-dom'
import { VerifiedBadge } from '../verified-badge'
import { useAuthDataState } from '@/routes/shared/_authDataState'
import { maskPhoneNumberCognito } from '@/routes/shared/utils'
export function SmsWidget({ smsIsActive }: { smsIsActive: boolean }) {
  const navigate = useNavigate()
  const borderColor = smsIsActive ? 'border-green-500' : 'border-gray-200'
  const { data: user } = useAuthDataState()
  return (
    <div
      onClick={() => {
        if (!smsIsActive) {
          navigate('/dashboard/profile/mfa/sms-setup')
        }
      }}
      className={`border ${borderColor} rounded-lg p-5 flex justify-between items-start ${!smsIsActive ? 'hover:bg-gray-50 cursor-pointer' : ''} transition-colors`}
    >
      <div className="flex gap-5 items-start">
        <img src="/message.svg" className="mt-1 w-[20px] h-[20px]" />
        <div>
          <h3 className="font-medium mb-2">Set Up SMS Verification</h3>
          <p className="text-gray-600">
            Receive a one-time code via text message to verify your identity
            when you log in.
          </p>
          {smsIsActive && (
            <>
              <p className="text-gray-600 pt-2 pb-2">
                <strong>Mobile number: </strong> {maskPhoneNumberCognito(user)}
              </p>
              <p className="text-gray-600">
                Need to{' '}
                <Link
                  className="underline"
                  to="/dashboard/profile/mfa/sms-setup"
                >
                  update your phone number?
                </Link>
              </p>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-col items-end gap-2">
        {smsIsActive && (
          <VerifiedBadge isVerified={true} verifiedText="Active" />
        )}
        {!smsIsActive && <div className="text-blue-600 text-2xl">›</div>}
      </div>
    </div>
  )
}
