export const amount = {
  howMuchToInvest: `Next, please enter how much you would like to invest.`,
  minMax: `Min. {{min}} - Max {{max}}`,
  min: `You have entered an amount below the minimum of {{min}}.`,
  step: `The amount should be a multiple of {{step}}.`,
  max: `You have entered an amount above the maximum of {{max}}.`,
  investmentLimits: `Investment Limits`,
  investmentLimitsModal: `Investors who are not accredited, as defined under Rule 501(a) of Regulation D, may not invest more than 10% of the greater of their annual income or net worth (for natural persons), or 10% of the greater of annual revenue or net assets at fiscal year-end (for non-natural persons).`,
  distributions: `Distributions`,
  distributionsModal: `There is no guarantee that the Income REIT’s or Apartment Growth REIT’s members or shareholders, respectively, will receive a distribution. Distributions have been paid from sources other than cash flow from operations, including net proceeds from the offerings, cash advances by RM Adviser, LLC, manager of the Income REIT and Apartment Growth REIT (the “Manager”), cash resulting from a waiver of fees or reimbursements due to Manager, borrowings and the issuance of additional securities. The Manager of Income REIT or the Board of Directors of Apartment Growth REIT may in the future declare lower distributions or no distributions at all for any given period.

By selecting to participate in the Distribution Reinvestment Plan (DRIP), you are agreeing to monitor and notify RM Adviser, through RealtyMogul.com, in advance of the event that your continued participation in the DRIP would cause you to exceed applicable qualified purchaser limits as set forth in Regulation A of the Securities Act, and that you will terminate your participation in the DRIP.`,
  howDistributions: `Choose your preferred distribution method.`,
  reinvestInvestors: `The majority of investors choose to reinvest their distributions.`,
  learnMore: `Learn more`,
  reinvestInvestorsModalTitle: `Reinvesting Distributions`,
  reinvestInvestorsModal: `<p>
    Investors who reinvest distributions will accumulate additional shares
    according to the Distribution Reinvestment Plan.</p>
  <p>
    Choose to reinvest your distributions for an additional compound benefit on
    your investment.
  </p>
  <p>
    As with all investments, adding additional capital may allow for potentially
    greater risk of loss.
  </p>
  <p>
    Interested in making your money potentially grow faster for you? Choose to
    reinvest your distributions.
  </p>`,
  chooseDistribution: `Choose Distribution Option`,
  reinvestDistributions: `Reinvest Distributions`,
  cashOutDistributions: `Cash Out Distributions`,
  pledgeAmount: `Pledge Amount`,
  pledgeCopy: `<p>Next, please enter how much you would like to pledge.</p>
  <p>
    By pledging your investment, you will have first priority when the investment
    is open for funding. You will be notified 24 hours in advance of other
    investors to complete your investment.
  </p>
  <p>
    All pledges are non-binding until you have had the opportunity to review and execute final legal documentation.
  </p>`,
  decimals: 'Please enter no more than two decimal places.',
  autoInvestMonthly: `Monthly Auto-Invest Option`,
  autoInvestMonthlyOption: `Monthly Auto-Invest Option`,
  autoInvestMonthlyModal1: `Optional monthly recurring investments allow you to potentially grow your investment through compounding and can be cancelled by you at any time. The minimum investment is $250 per month.`,
  autoInvestMonthlyModal2: `By selecting to participate in the Auto Invest Program, you are agreeing to monitor and notify us in advance in the event that your continued participation in the Auto Invest Program would cause you to exceed applicable qualified purchaser limits as set forth in Regulation A of the Securities Act, and that you will terminate your participation in the program.`,
}
