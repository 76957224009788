import { useNavigate } from 'react-router-dom'
import { VerifiedBadge } from '../verified-badge'

export function AuthenticatorWidget({
  authenticatorAppIsActive,
}: {
  authenticatorAppIsActive: boolean
}) {
  const navigate = useNavigate()
  const borderColor = authenticatorAppIsActive
    ? 'border-green-500'
    : 'border-gray-200'
  return (
    <div
      onClick={() => {
        if (!authenticatorAppIsActive) {
          navigate('/dashboard/profile/mfa/authenticator-setup')
        }
      }}
      className={`border ${borderColor} rounded-lg p-5 flex justify-between items-start ${!authenticatorAppIsActive ? 'hover:bg-gray-50 cursor-pointer' : ''} transition-colors`}
    >
      <div className="flex gap-5 items-start">
        <img src="/phone.svg" className="mt-1 w-[14px] h-[21px]" />
        <div>
          <h3 className="font-medium mb-2">
            Use an Authenticator App
            <span className="bg-gray-100 text-gray-600 text-xs px-2 py-1 rounded-full ml-2">
              <i className="mr-1 fa-regular fa-shield-check" />
              Most secure
            </span>
          </h3>
          <p className="text-gray-600">
            Use an authenticator app on your device to verify your identity when
            you log in.
          </p>
        </div>
      </div>
      <div className="flex flex-col items-end gap-2">
        {authenticatorAppIsActive && (
          <VerifiedBadge isVerified={true} verifiedText="Active" />
        )}
        {!authenticatorAppIsActive && (
          <div className="text-blue-600 text-2xl">›</div>
        )}
      </div>
    </div>
  )
}
