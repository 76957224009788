import { useState } from 'react'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import { useProfile } from '../../../api/get-profile'
import { useForm } from 'react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input/react-hook-form'
import { Dispatch, SetStateAction } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useAuthDataState } from '@/routes/shared/_authDataState'

interface EnterPhoneProps {
  setScreen: Dispatch<SetStateAction<'enter-phone' | 'verify-phone'>>
}

export const EnterPhone = ({ setScreen }: EnterPhoneProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const queryClient = useQueryClient()
  const { data: user } = useAuthDataState()
  const {
    data: { phone_number: userPhoneNumber } = {},
    isLoading: isProfileLoading,
  } = useProfile()
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      phone_number: user?.attributes?.phone_number || userPhoneNumber || '',
    },
  })

  if (isProfileLoading) {
    return (
      <div className="max-w-lg p-6">
        <div>Loading profile...</div>
      </div>
    )
  }

  const onSubmit = async (data: { phone_number: string }) => {
    setIsLoading(true)

    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, {
        phone_number: data.phone_number,
      })
      await Auth.verifyCurrentUserAttribute('phone_number')
      setScreen('verify-phone')
      queryClient.invalidateQueries(['auth'])
    } catch (error) {
      console.error('Error updating phone number:', error)
      // Handle error appropriately
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="max-w-lg">
      <p className="text-gray-600 mb-6">
        Request a one-time verification code to verify the phone number we'll
        use for two-factor authentication.
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6">
          <label
            htmlFor="phone_number"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Mobile Number
          </label>
          <PhoneInput
            name="phone_number"
            className="phone-input-wrapper mb-2 h-[42px] w-full rounded border border-gray-300 px-3 py-1.5 text-16 focus-within:outline-none focus-within:ring-1 focus-within:border-primary"
            defaultCountry="US"
            countryOptionsOrder={['US']}
            control={control}
            initialValueFormat="national"
            limitMaxLength={true}
            rules={{
              required: 'Phone number is required',
              validate: {
                isValidPhoneNumber: (value: string) =>
                  isValidPhoneNumber(value) ||
                  'Please enter a valid phone number.',
              },
            }}
          />
          {errors?.phone_number?.message && (
            <p className="mt-2 text-sm text-red-600">
              {errors?.phone_number?.message as string}
            </p>
          )}
          <p className="mt-2 text-sm text-gray-500">
            US phone number only. Message and data rates may apply.
          </p>
        </div>

        <div className="flex gap-4">
          <button
            type="submit"
            disabled={isLoading}
            className="bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
          >
            {isLoading ? 'Processing...' : 'Continue'}
          </button>

          <button
            type="button"
            onClick={() => navigate(-1)}
            className="px-6 py-2 border border-blue-500 text-blue-500 rounded-md hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Back
          </button>
        </div>
      </form>

      <div className="mt-8 text-sm text-gray-600">
        Questions? Our Investor Relations team is available to help 9 AM - 8 PM
        ET Monday to Friday. Contact us at (877) 977-2776.
      </div>
    </div>
  )
}
