import { useTranslation } from 'react-i18next'
import { useInvestDataState } from '../Invest/_investDataState'

const COMPANY_LINKS = [
  { label: 'Our Story', href: 'https://www.realtymogul.com/our-story' },
  {
    label: 'RM Communities',
    href: 'https://www.realtymogul.com/rm-communities',
  },
  { label: 'Contact Us', href: 'https://www.realtymogul.com/contact-us' },
  { label: 'Careers', href: 'https://www.realtymogul.com/careers' },
  {
    label: 'Newsworthy',
    href: 'https://www.realtymogul.com/knowledge-center/press',
  },
]

const INVEST_LINKS = [
  {
    label: 'Benefits of Investing',
    href: 'https://www.realtymogul.com/benefits-of-investing',
  },
  {
    label: 'How It Works',
    href: 'https://www.realtymogul.com/how-it-works',
  },
  {
    label: 'Investment Options',
    href: 'https://www.realtymogul.com/investment-options',
  },
  { label: 'Auto Invest', href: 'https://www.realtymogul.com/auto-invest' },
]

const LEARN_LINKS = [
  {
    label: 'Knowledge Center',
    href: 'https://www.realtymogul.com/knowledge-center',
  },
  {
    label: 'Why Commercial Real Estate',
    href: 'https://www.realtymogul.com/knowledge-center/why-commercial-real-estate',
  },
  {
    label: 'Why REITs',
    href: 'https://www.realtymogul.com/knowledge-center/why-reits',
  },
  {
    label: 'Retirement Investing',
    href: 'https://www.realtymogul.com/real-estate-investing-with-your-ira',
  },
  {
    label: 'FAQs',
    href: 'https://www.realtymogul.com/knowledge-center/faqs',
  },
  {
    label: 'Glossary',
    href: 'https://www.realtymogul.com/knowledge-center/glossary',
  },
]

const FINANCING_LINKS = [
  {
    label: 'Financing Overview',
    href: 'https://www.realtymogul.com/financing/overview',
  },
  {
    label: 'Our JV Equity Process',
    href: 'https://www.realtymogul.com/financing/jv-equity',
  },
  {
    label: 'Apply for Equity Capital',
    href: 'https://www.realtymogul.com/apply-for-equity-capital',
  },
]

const GroupLinks = ({
  header,
  links,
}: {
  header: string
  links: { label: string; href: string }[]
}) => {
  return (
    <div className="mb-[20px]">
      <div className="mb-[20px] text-[13px] text-[#9b9b9b] tracking-[1.56px] leading-[1.85] uppercase">
        {header}
      </div>
      {links.map((link, index) => (
        <a
          key={index}
          className="block mb-[12px] hover:text-[#9b9b9b] text-base"
          href={link.href}
        >
          {link.label}
        </a>
      ))}
    </div>
  )
}

export const Footer = () => {
  const { t } = useTranslation()
  const { data: investData } = useInvestDataState()

  return (
    <div className="max-w-[1400px] h-full bg-black text-white w-full px-[30px] py-[40px] md:px-[60px] md:py-[45px] xl:py-[60px] mx-auto">
      <div className="w-full">
        <div className="w-full grid gap-4 md:grid-cols-3 xl:grid-cols-5">
          <GroupLinks header="Company" links={COMPANY_LINKS} />
          <GroupLinks header="Invest" links={INVEST_LINKS} />
          <GroupLinks header="Learn" links={LEARN_LINKS} />
          <GroupLinks header="Financing" links={FINANCING_LINKS} />
          <div>
            <a
              className="mb-[20px] text-[13px] text-[#9b9b9b] tracking-[1.56px] leading-[1.85] uppercase"
              href="tel://+18779772776"
            >
              Call (877) 977-2776
            </a>
          </div>
        </div>
        <div className="w-full mt-4 md:mt-6 xl:mt-10 pt-5 border-t border-t-[#303030] ">
          <div className="w-full flex flex-wrap">
            <div className="font-light text-[13px] text-[#9b9b9b] leading-[1.77]">
              {t('copyRight', {
                ns: 'common',
                year: new Date().getFullYear(),
              })}
            </div>
            <div className="flex-grow">
              <div className="flex mt-2 flex-wrap md:mt-0 md:justify-end gap-4">
                <a
                  href="https://www.facebook.com/RealtyMogul"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://twitter.com/realty_mogul"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/realty-mogul"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a
                  href="https://www.instagram.com/realty_mogul/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.youtube.com/c/Realtymogul"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        {investData?.opportunity?.offering_entity_name ===
        'RM Technologies LLC' ? (
          <div className="w-full mt-8 font-light text-[11px] text-[#9b9b9b] leading-[1.77]">
            Information in this message, including information regarding
            targeted returns and investment performance, is provided by the
            sponsor of the investment opportunity and is subject to change.
            Forward-looking statements, hypothetical information or
            calculations, financial estimates and targeted returns are
            inherently uncertain. Such information should not be used as a
            primary basis for an investor's decision to invest. Investment
            opportunities on the RealtyMogul Platform are speculative and
            involve substantial risk. You should not invest unless you can
            sustain the risk of loss of capital, including the risk of total
            loss of capital. Please see additional disclosures{' '}
            <a
              href="https://www.realtymogul.com/investment-disclosure"
              className="underline"
              target="_blank"
            >
              here
            </a>
            .
          </div>
        ) : (
          <>
            <div>
              <div className="w-full mt-6 text-[13px] text-center">
                <a
                  href="https://www.realtymogul.com/legal-tos"
                  className="text-[#F5F5F5]"
                >
                  {t('common:termsOfService')}
                </a>
                <span className="text-[#F5F5F5]"> | </span>
                <a
                  href="https://www.realtymogul.com/legal-privacy-policy"
                  className="text-[#F5F5F5]"
                >
                  {t('common:privacyPolicy')}
                </a>
                <span className="text-[#F5F5F5]"> | </span>
                <a
                  href="https://www.realtymogul.com/investment-disclosure"
                  className="text-[#F5F5F5]"
                >
                  {t('common:disclosures')}
                </a>
                <span className="text-[#F5F5F5]"> | </span>
                <a
                  href="https://www.realtymogul.com/legal-form-crs"
                  className="text-[#F5F5F5]"
                >
                  {t('footer:crs')}
                </a>
                <span className="text-[#F5F5F5]"> | </span>
                <a
                  href="https://www.realtymogul.com/legal-regulation-best-interest"
                  className="text-[#F5F5F5]"
                >
                  {t('footer:regBestInterest')}
                </a>
                <span className="text-[#F5F5F5]"> | </span>
                <a
                  href="https://www.realtymogul.com/legal-business-continuity-plan"
                  className="text-[#F5F5F5]"
                >
                  {t('footer:bcp')}
                </a>
              </div>
            </div>
            <div className="w-full mt-8 font-light text-[11px] text-[#9b9b9b] leading-[1.77]">
              <p className="mb-4">
                {t('footer:tsAgreement')}{' '}
                <a
                  href="https://www.realtymogul.com/terms-service"
                  target="_blank"
                  className="underline text-white"
                >
                  {t('common:termsOfService')}
                </a>{' '}
                and{' '}
                <a
                  href="https://www.realtymogul.com/privacy-policy"
                  target="_blank"
                  className="underline text-white"
                >
                  {t('common:privacyPolicy')}
                </a>
                .
                <br />
                <br />
                {t('footer:brokerageServices')}{' '}
                <a
                  href="https://www.finra.org/"
                  target="_blank"
                  className="underline text-white"
                >
                  {t('common:finra')}
                </a>
                /
                <a
                  href="https://www.sipc.org/"
                  target="_blank"
                  className="underline text-white"
                >
                  {t('common:sipc')}
                </a>
                {t('footer:brokerageServices2')}{' '}
                <a
                  href="https://brokercheck.finra.org/"
                  target="_blank"
                  className="underline text-white"
                >
                  {t('footer:finraBrokerCheck')}
                </a>
                .{'  '}
                {t('footer:brokerageServices3')}
              </p>
              <p className="mb-4">
                <a
                  href="https://reports.adviserinfo.sec.gov/reports/ADV/283877/PDF/283877.pdf"
                  target="_blank"
                  className="underline text-white"
                >
                  RM Adviser, LLC
                </a>
                {', '}
                {t('footer:rmAdvisorLLCTerms')}
              </p>
              <p className="mb-4">{t('footer:considerObjectivesDisclosure')}</p>
              <p className="mb-4">{t('footer:allInformationDiscloser')}</p>
              <p className="font-bold">
                {t('footer:adviceFromSecuritiesProfessional')}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Footer
