import { z } from 'zod'

export const messagesFilterSchema = z.array(
  z.object({
    name: z.string(),
    uuid: z.string(),
  })
)

export const messagesFiltersSchema = z.object({
  investment_accounts: messagesFilterSchema,
  investments: messagesFilterSchema,
})

export type MessagesFilterTypes = z.infer<typeof messagesFiltersSchema>

export const messagesAttachmentsSchema = z.object({
  file_name: z.string(),
  document_url: z.string().url(),
})

export const messageSchema = z.object({
  uuid: z.string(),
  investment_account_name: z.string().nullable(),
  investment_account_type: z.string().nullable(),
  io_name: z.string().nullable(),
  io_uuid: z.string().uuid().nullable(),
  subject: z.string(),
  body: z.string(),
  message_date: z.string(),
  unread: z.boolean(),
  attachments: z.array(messagesAttachmentsSchema),
})

export type MessageType = z.infer<typeof messageSchema>

export const messagesDataSchema = z.object({
  count: z.number().int().min(0),
  page: z.number().int().min(0),
  pages: z.number().int().min(0),
  messages: z.array(messageSchema),
})

export type MessagesDataTypes = z.infer<typeof messagesDataSchema>
