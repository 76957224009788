import { cn } from '@/utils/utils'
import { Link } from 'react-router-dom'

type EmptyStateTypes = {
  iconClasses?: string
  copy: string
  secondaryCopy?: string
  linkCopy: string
  linkTo: string
}

function EmptyState({
  iconClasses = '',
  copy,
  secondaryCopy = '',
  linkCopy,
  linkTo,
}: EmptyStateTypes) {
  return (
    <div
      className="w-max-[200px] flex flex-col text-center items-center gap-8 mt-4"
      data-cy="empty-state"
    >
      <i className={cn('', iconClasses)} />
      <div className="flex flex-col items-center gap-2">
        <div className="text-content-black font-medium">{copy}</div>
        {secondaryCopy && (
          <div className="text-content-black">{secondaryCopy}</div>
        )}
      </div>
      <Link
        className="flex h-9 px-4 py-[9px] justify-center font-medium text-base text-white items-center gap-2 flex-shrink-0 rounded bg-primary hover:bg-primary-hover"
        to={linkTo}
      >
        {linkCopy}
      </Link>
    </div>
  )
}

export { EmptyState }
