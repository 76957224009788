export const account = {
  step: `Next, select an ownership type below to use for this investment.`,
  confirmIndividualInfo: `Please confirm the individual investor information below.`,
  selectOrCreateJoint: `Select the joint investor below, or create a new one.`,
  selectJoint: `Select Joint Investor`,
  addNewJoint: `Add a new Joint Investor`,
  jointInfo: `Joint Investor's Information`,
  spouse: `Spouse of Primary Investor`,
  jointType: `Type of Joint Investor`,
  communityProperty: `Community Property`,
  survivorship: `Right of Survivorship`,
  jointInfoContact: `Joint Investor Contact Info`,
  sameAddress: `Joint investor's address and phone number is the same as your personal address/phone number.`,
  enterEntity: `Enter the entity details. If there are other control
  people associated with your entity, we may need to collect
  additional information from each of them before finalizing any
  investments.`,
  selectEntity: `Select Entity`,
  nameEntity: `Name of Entity`,
  titleEntity: `Your Title Within Entity`,
  entityType: `Type of Entity`,
  numberOfMembers: `Number of Members`,
  isDisregardedEntity: `Is this a disregarded entity?`,
  disregardedEntity: `Disregarded Entity`,
  deModal: `Per IRS definition, a disregarded entity is <strong> a business with a single owner that is not separate from the owner for federal income tax purposes</strong>.
  <br /><br />
  This means taxes owed by this type of business are paid as part of the owner's income tax return.`,
  addNewEntity: `Add a new Entity`,
  jurisdictionOfRegistration: `Jurisdiction of Registration`,
  sameAddressEntity: `The entity's address and phone number is the same as your personal address/phone number.`,
  certificateOfFormation: `Certificate of Formation`,
  operatingAgreement: `Operating Agreement`,
  trustFilesLater: `I will provide a copy of the Trust Agreement later. I acknowledge that my investment will not be processed until I provide these documents.`,
  entityFilesLater: `I will provide a copy of the Certificate of Formation and a copy of the Operating Agreement later. I acknowledge that my investment will not be processed until I provide these documents.`,
  trustDetails: `Enter the trust details.`,
  selectTrust: `Select the trust below, or create a new one.`,
  trustAccount: `Select Trust`,
  addNewTrust: `Add a new Trust`,
  nameTrust: `Name of Trust`,
  tinLabel: `Tax Identification number (TIN)`,
  tinModalEntity: `The Entity's Tax Identification Number is required for us to produce annual tax documentation.`,
  tinModalTrust: `The Trust's Tax Identification Number is required for us to produce annual tax documentation. The Tax ID number for your trust could possibly be your personal Social Security Number if it is a living trust.`,

  titleWithinTrust: `Your Title Within Trust`,
  titleWithinTrustModal: `The most common title is "Trustee".`,
  typeOfTrust: `Type of Trust`,
  typeOfTrustModal1: `The assets in a revocable trust remain in the grantor's estate. With an irrevocable trust, those assets are no longer part of the grantor's estate.`,
  typeOfTrustModal2: `Most living trusts are "revocable" because you can change them as your circumstances or wishes change. Revocable living trusts are "living" because you make them during your lifetime.`,
  realEstateAdviceDisclaimer: `<i>Please note, RealtyMogul does not provide estate planning or tax advice and we suggest you reach out to your estate planning or tax advisor for questions regarding the use of trusts.</i>`,
  revocableTrust: `Revocable Trust`,
  irrevocableTrust: `Irrevocable Trust`,
  dateOfFormation: `Date of Formation`,
  trustSameAddress: `The Trust’s address and phone number is the same as your personal address/phone number.`,
  trustAgreementCopy: `Copy of Trust Agreement`,
  trustFileLater: `I will provide a copy of the Trust Agreement later. I acknowledge that my investment will not be processed until I provide these documents.`,
  sdiraCustodian: `Select your IRA custodian below.`,
  sdiraName: `Custodian Name`,
}
