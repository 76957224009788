import { FALLBACK_RM_IMAGE, formatCurrency } from '@/utils/helpers'
import { cn } from '@/utils/utils'
import { getBrightspotUrl } from '@/routes/shared/_api'
import { InvestmentDataTypes } from '../api/schema'

import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { PendingInvestmentMenu } from './edit-menu-pending'

const PendingBadge = ({ investment }: { investment: InvestmentDataTypes }) => (
  <div className="flex flex-col md:min-w-[170px] justify-start items-center">
    {((investment.route === 'completed' &&
      !investment.investment?.wire_instructions) ||
      investment.investment?.funding_status === 'ach_pending' ||
      investment.investment?.funding_status === 'ach_settled' ||
      investment.investment?.funding_status === 'wire_settled') && (
      <Badge variant="active">Pending</Badge>
    )}
    {investment.route !== 'funded' &&
      investment.route !== 'retirementcomplete' &&
      investment.route !== 'completed' && (
        <Badge variant="incomplete">Incomplete</Badge>
      )}
    {investment.investment?.wire_instructions && (
      <Badge variant="incomplete">Awaiting Wire</Badge>
    )}
    {investment.route === 'funded' &&
      !(
        investment.investment?.funding_status === 'ach_pending' ||
        investment.investment?.funding_status === 'ach_settled' ||
        investment.investment?.funding_status === 'wire_settled'
      ) && <Badge variant="completed">No Longer Available</Badge>}
  </div>
)

const PendingButton = ({ investment }: { investment: InvestmentDataTypes }) => (
  <div className="flex flex-grow gap-2 flex-col justify-start text-center text-sm min-w-[220px]">
    {((investment.route !== 'completed' &&
      investment.route !== 'retirementcomplete') ||
      investment.investment?.wire_instructions) &&
      investment.route !== 'funded' && (
        <a href={`/invest/${investment.io_uuid}`}>
          <Button className="w-full" variant="RM" size="RM">
            Continue <i className="ml-2 fa-regular fa-angle-right" />
          </Button>
        </a>
      )}
    {((investment.route === 'completed' &&
      !investment.investment?.wire_instructions) ||
      (investment.route === 'retirementcomplete' &&
        !investment.investment?.wire_instructions) ||
      investment.investment?.funding_status === 'ach_pending' ||
      investment.investment?.funding_status === 'ach_settled' ||
      investment.investment?.funding_status === 'wire_settled') && (
      <div className="text-nowrap text-center py-2">No action required.</div>
    )}
    {investment.investment?.wire_instructions && (
      <div className="text-nowrap text-center py-2">
        Looking for{' '}
        <a className="underline" href={`/invest/${investment.io_uuid}`}>
          wire instructions
        </a>
        ?
      </div>
    )}
    {investment.route === 'funded' &&
      !(
        investment.investment?.funding_status === 'ach_pending' ||
        investment.investment?.funding_status === 'ach_settled' ||
        investment.investment?.funding_status === 'wire_settled'
      ) && (
        <div className="text-nowrap text-center text-content-black py-2">
          Browse current{' '}
          <a
            className="underline"
            href={`${getBrightspotUrl()}/investment-opportunities`}
          >
            investment offerings
          </a>
          .
        </div>
      )}
  </div>
)

export const PendingInvestmentCard = ({
  investment,
}: {
  investment: InvestmentDataTypes
}) => {
  return (
    <div
      className={cn(
        'w-full flex flex-wrap md:flex-nowrap md:gap-8 overflow-hidden md:h-[106px]',
        investment.route === 'funded' &&
          !(
            investment.investment?.funding_status === 'ach_pending' ||
            investment.investment?.funding_status === 'ach_settled' ||
            investment.investment?.funding_status === 'wire_settled'
          ) &&
          'bg-gray-50 text-content-light'
      )}
    >
      <div className="w-full h-[89px] md:h-[106px] md:w-[180px]">
        <img
          className={cn(
            'w-full h-full object-cover md:object-fill',
            investment.route === 'funded' &&
              !(
                investment.investment?.funding_status === 'ach_pending' ||
                investment.investment?.funding_status === 'ach_settled' ||
                investment.investment?.funding_status === 'wire_settled'
              ) &&
              'grayscale'
          )}
          src={investment.image ?? FALLBACK_RM_IMAGE}
        />
      </div>
      <div className="w-[85%] hidden md:flex gap-6 p-4 md:px-0">
        <div className="flex w-[30%] flex-col h-full gap-1 justify-center">
          <div>
            <p className="text-[17px] font-medium line-clamp-2">
              {investment.name}
            </p>
          </div>
          <div className="text-16">
            <p className="line-clamp-1">{investment.subhead}</p>
          </div>
          <div className="text-base text-content-light">
            {investment.property_type}
          </div>
        </div>

        <div className="flex w-[20%] text-16 font-medium gap-2 flex-col justify-start text-right">
          {investment.investment_value && (
            <span>{formatCurrency(investment.investment_value ?? '')}</span>
          )}
        </div>
        <PendingBadge investment={investment} />
        <PendingButton investment={investment} />
        <div onClick={(e) => e.stopPropagation()} className="-mt-3">
          <PendingInvestmentMenu
            id={investment.io_drupal_id.toString()}
            investment_uuid={investment.investment?.uuid ?? ''}
            route={investment?.route}
          />
        </div>
      </div>
      <div className="md:hidden w-full p-4 flex flex-col gap-4">
        <div className="w-full flex justify-between">
          <PendingBadge investment={investment} />
          <PendingInvestmentMenu
            id={investment.io_drupal_id.toString()}
            investment_uuid={investment.investment?.uuid ?? ''}
            route={investment?.route}
          />
        </div>
        <div className="w-full flex flex-col gap-[2px]">
          <div>
            <p className="text-[17px] font-medium line-clamp-2">
              {investment.name}
            </p>
          </div>
          <div className="text-16">{investment.subhead}</div>
          <div className="text-base text-content-light">
            {investment.property_type}
          </div>
        </div>
        {investment.investment_value && (
          <div className="w-full flex justify-between text-base">
            <div>Investment Amount</div>
            <div className="font-medium">
              <span>{formatCurrency(investment.investment_value ?? '')}</span>
            </div>
          </div>
        )}
      </div>
      <div className="md:hidden w-full p-4 flex flex-col justify-center border-t border-border-normal">
        <PendingButton investment={investment} />
      </div>
    </div>
  )
}
