import { z } from 'zod'
import { addressSchema } from '../../shared/address-schema'

const memberSchema = z.object({
  uuid: z.string().uuid(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  has_approved_investments: z.boolean(),
  birthdate: z.string().nullable(),
  address: addressSchema.nullable(),
  phone_number: z.string().nullable(),
})

const fileSchema = z.object({
  uuid: z.string().uuid(),
  file_type: z.string(),
  bucket: z.string(),
  key: z.string(),
  file_name: z.string(),
  uploaded: z.boolean(),
  url: z.string().url(),
})

export const accountSchema = z.object({
  uuid: z.string().uuid(),
  subtype: z.string().nullable(),
  type: z.literal('individual'),
  investment_account_name: z.string().nullable(),
  address: addressSchema.nullable(),
  account_phone_number: z.string().nullish(),
  primary_member: memberSchema,
  phone_number: z.string().nullish(),
  use_primary_investor_contact: z
    .boolean()
    .nullish()
    .transform((x) => x ?? undefined),
  total_investment_count: z.number().int().min(0).optional(),
})

export const jointAccountSchema = accountSchema.extend({
  type: z.literal('joint_ownership'),
  subtype: z.enum(['survivorship', 'community']),
  joint_is_spouse: z.boolean(),
  secondary_member: memberSchema,
})

export const trustAccountSchema = accountSchema.extend({
  type: z.literal('foe_trust'),
  title_within_trust: z.string().nullish(),
  date_of_formation: z.string().nullable(),
  tin: z.string().nullable(),
  is_disregarded_entity: z.boolean().nullable(),
  jurisdiction_of_registration: z.string().nullable(),
  trust_agreement: fileSchema.nullable(),
})

export const retirementAccountSchema = accountSchema.extend({
  type: z.literal('foe_sdira'),
  custodian: z
    .object({
      name: z.string(),
    })
    .nullable(),
})

export const entityAccountSchema = accountSchema.extend({
  type: z.literal('foe_entity'),
  title: z.string(),
  tin: z.string().nullable(),
  jurisdiction_of_registration: z.string().nullable(),
  is_disregarded_entity: z.boolean().nullable(),
  certificate_of_formation: fileSchema.nullable(),
  operating_agreement: fileSchema.nullable(),
  number_of_members: z.number().int().min(0).nullish(),
})

export const allAccountSchema = z.discriminatedUnion('type', [
  jointAccountSchema,
  retirementAccountSchema,
  trustAccountSchema,
  entityAccountSchema,
  accountSchema,
])

export type AllAccountTypes = z.infer<typeof allAccountSchema>

const accountDataSchema = z.object({
  uuid: z.string().uuid(),
  subtype: z.string().nullable(),
  type: z.enum([
    'individual',
    'joint_ownership',
    'foe_sdira',
    'foe_entity',
    'foe_trust',
  ]),
  investment_account_name: z.string().nullable(),
  address: addressSchema.nullable(),
  account_phone_number: z.string().nullish(),
  primary_member: memberSchema,
  use_primary_investor_contact: z
    .boolean()
    .nullish()
    .transform((x) => x ?? undefined),
  total_investment_count: z.number().int().min(0).optional(),
})

export type AccountDataTypes = z.infer<typeof accountDataSchema>

export const accountsSchema = z.object({
  count: z.number().int().min(0),
  page: z.number().int().min(0),
  pages: z.number().int().min(0),
  accounts: z.array(accountDataSchema),
})

export type AccountsDataTypes = z.infer<typeof accountsSchema>
