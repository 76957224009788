import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { useInvestDataState } from '../_investDataState'
import { useAmountDataState, useAmountMutation } from '../_amountDataState'
import { moneyMask } from '../../../utils/helpers'
import FormFooter from '../../shared/FormFooter'
import InfoModal from '../../../components/InfoModal'
import MoneyInput from '../../../components/MoneyInput'
import Radio from '../../../components/Radio'
import Sidebar from '../Sidebar'
import Stepper from '../../../components/Stepper'
import ErrorAlert from '../../../components/ErrorAlert'
import ServerError from '../../../components/ErrorAlert/ServerError'
import Loader from '../../../components/Loader'
import { useRoute } from '../../../rules/find-route'
import { AmountTypes } from '../../shared/_types'

interface FormType {
  amount: string
  autoinvest_amount?: string
  drip?: string
}

const ReitAmountScreen = () => {
  const [serverError, setServerError] = useState('')
  const [disableAutoInvest, _setDisableAutoInvest] = useState(true)
  const nav = useNavigate()
  const { data: investData } = useInvestDataState()
  const { data: amountData } = useAmountDataState()
  const { isLoading, mutateAsync: mutateAmount } = useAmountMutation()
  const { t } = useTranslation()
  const { findRoute } = useRoute()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormType>()

  // const watchAmount = watch('amount')

  useEffect(() => {
    if (investData && investData?.opportunity?.io_type !== 'reit') {
      nav(`/invest/${investData.opportunity.id}/amount/pp`)
    }
    if (amountData) {
      reset({ amount: amountData.amount || amountData.amount_minimum })
    }
  }, [investData, nav, amountData, reset])

  // useEffect(() => {
  //   setDisableAutoInvest(
  //     Number(String(watchAmount).replace(/\D/g, '')) > 100_000
  //   )
  // }, [setDisableAutoInvest, watchAmount])

  const onSubmit = handleSubmit(async (data: FormType) => {
    console.log(errors, data)
    const cleanData = {
      amount: Number(String(data.amount).replace(/\D/g, '')),
    } as AmountTypes

    // if (data.autoinvest_amount && data.autoinvest_amount !== '0') {
    //   cleanData.autoinvest_amount = Number(
    //     String(data?.autoinvest_amount).replace(/\D/g, '')
    //   )
    // }
    if (data.drip) {
      cleanData.drip = data.drip === 'reinvest'
    }
    mutateAmount(cleanData)
      .then(() => {
        findRoute({
          ...investData,
          investment: {
            ...investData?.investment,
            amount: Number(String(data.amount).replace(/\D/g, '')),
          },
          investment_state: {
            ...investData?.investment_state,
            amount: 'submitted',
            self_accreditation: 'submitted',
          },
        }).then((route) => {
          nav(`/invest/${investData.opportunity.id}/${route.type}`)
        })
      })
      .catch((error) => {
        console.error(error.message)
        setServerError(error.message)
      })
  })

  // const backLocation = location?.state?.from
  //   ? location?.state?.from
  //   : `/invest/${investData?.investment.id}/amount/reit-income`

  return (
    <div className="p-6 xl:p-0">
      <Stepper currentSection={1} currentStep={2} totalStepCount={10} />
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-6 text-2xl font-bold text-content-black">
            {t('common:investmentAmount')}
          </h1>
          {amountData ? (
            <>
              <p className="mb-6 text-16 text-content-black">
                {t('amount:howMuchToInvest')}
              </p>
              <form onSubmit={onSubmit} className="mb-14 xl:">
                <div className="mb-6">
                  {Object.keys(errors).length > 0 && (
                    <ErrorAlert text="Please correct the errors below:" />
                  )}
                  <Controller
                    name="amount"
                    control={control}
                    rules={{
                      required: 'is required',
                      validate: {
                        min: (value) => {
                          if (
                            amountData?.amount_minimum &&
                            Number(String(value).replace(/\D/g, '')) <
                              Number(amountData?.amount_minimum)
                          )
                            return t('min', {
                              ns: 'amount',
                              min: `$${moneyMask(amountData?.amount_minimum)}`,
                            })
                        },
                        step: (value) => {
                          if (
                            amountData?.amount_step &&
                            Number(String(value).replace(/\D/g, '')) %
                              Number(amountData?.amount_step) !==
                              0
                          ) {
                            return t('step', {
                              ns: 'amount',
                              step: `$${moneyMask(amountData?.amount_step)}`,
                            })
                          }
                        },
                        max: (value) => {
                          if (amountData?.amount_maximum) {
                            if (
                              Number(String(value).replace(/\D/g, '')) >
                              Number(amountData?.amount_maximum)
                            )
                              return t('max', {
                                ns: 'amount',
                                max: `$${moneyMask(
                                  amountData?.amount_maximum
                                )}`,
                              })
                          }
                          return true
                        },
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <MoneyInput
                        label={
                          <span>
                            {t('common:investmentAmount')}{' '}
                            <InfoModal
                              modalContent={
                                <div className="p-8 text-16 font-normal text-content-black">
                                  <h2 className="mb-6 text-17 font-medium text-content-black">
                                    {t('amount:investmentLimits')}
                                  </h2>
                                  {t('amount:investmentLimitsModal')}
                                </div>
                              }
                            />
                          </span>
                        }
                        placeholder="$"
                        helpText={t('minMax', {
                          ns: 'amount',
                          min: `$${moneyMask(amountData?.amount_minimum)}`,
                          max: amountData?.amount_maximum
                            ? `$${moneyMask(amountData?.amount_maximum)}`
                            : `unlimited`,
                        })}
                        error={fieldState.error}
                        {...field}
                      />
                    )}
                  />
                </div>
                {!amountData.is_repeat && (
                  <>
                    {!disableAutoInvest && (
                      <div className="mb-4">
                        <Controller
                          name="autoinvest_amount"
                          control={control}
                          rules={{
                            validate: {
                              min: (value) => {
                                if (
                                  Number(String(value).replace(/\D/g, '')) <
                                    250 &&
                                  Number(String(value).replace(/\D/g, '')) !== 0
                                )
                                  return `Minimum Auto-Invest amount is $250`
                              },
                              max: (value) => {
                                if (!amountData?.autoinvest_maximum) return
                                if (
                                  Number(String(value).replace(/\D/g, '')) >
                                  amountData?.autoinvest_maximum
                                )
                                  return `Maximum Auto-Invest amount is $${amountData?.autoinvest_maximum}`
                              },
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <MoneyInput
                              label={
                                <span>
                                  {t('amount:autoInvestMonthly')}
                                  {` `}
                                  <InfoModal
                                    modalContent={
                                      <div className="p-8 text-16 font-normal text-content-black">
                                        <h2 className="mb-6 text-17 font-medium text-content-black">
                                          {t('amount:authoInvestMonthlyOption')}
                                        </h2>
                                        <p className="mb-6 font-sans text-16 font-normal text-content-black">
                                          {t('amount:autoInvestMonthlyModal1')}
                                        </p>
                                        <p className="mb-6 font-sans text-16 font-normal text-content-black">
                                          {t('amount:autoInvestMonthlyModal2')}
                                        </p>
                                      </div>
                                    }
                                  />
                                </span>
                              }
                              placeholder="$"
                              helpText={t('minMax', {
                                ns: 'amount',
                                min: `$${moneyMask(250)}`,
                                max: amountData?.autoinvest_maximum
                                  ? `$${moneyMask(amountData?.autoinvest_maximum)}`
                                  : `unlimited`,
                              })}
                              error={fieldState.error}
                              {...field}
                            />
                          )}
                        />
                      </div>
                    )}
                    <div className="mb-6 text-17 font-medium text-content-black flex items-center whitespace-pre-wrap">
                      {t('amount:distributions')}
                      <div className="ml-1 pb-0.5">
                        <InfoModal
                          modalContent={
                            <div className="p-8 text-16 font-normal text-content-black">
                              <h2 className="mb-6 text-17 font-medium text-content-black">
                                {t('amount:distributions')}
                              </h2>
                              {t('amount:distributionsModal')}
                            </div>
                          }
                        />
                      </div>
                    </div>
                    <p className="mb-6 text-16 text-content-black">
                      {t('amount:howDistributions')}
                    </p>
                    <span className="mb-6 block text-16 text-content-black">
                      {t('amount:reinvestInvestors')}{' '}
                      <InfoModal
                        textTarget={
                          <span className="cursor-pointer text-primary hover:text-primary-hover">
                            {t('amount:learnMore')}
                          </span>
                        }
                        modalContent={
                          <div className="p-8 text-16 font-normal text-content-black">
                            <h2 className="mb-6 text-17 font-medium text-content-black">
                              {t('amount:reinvestInvestorsModalTitle')}
                            </h2>
                            <div className="space-y-4">
                              <Trans
                                i18nKey="reinvestInvestorsModal"
                                ns="amount"
                              />
                            </div>
                          </div>
                        }
                      />
                      .
                    </span>
                    <div className="mb-5">
                      <p className="mb-2 text-16 text-content-black">
                        <Trans i18nKey="chooseDistribution" ns="amount" />
                      </p>
                      <Controller
                        name="drip"
                        control={control}
                        rules={{ required: 'Distribution Option is required' }}
                        render={({ field, fieldState }) => (
                          <div
                            className={`flex flex-col gap-px ${
                              fieldState.error && 'border border-alert'
                            }`}
                          >
                            <Radio
                              {...field}
                              value="reinvest"
                              label={t('amount:reinvestDistributions')}
                              checked={field.value === 'reinvest'}
                            />
                            <Radio
                              {...field}
                              value="cashout"
                              label={t('amount:cashOutDistributions')}
                              checked={field.value === 'cashout'}
                            />
                          </div>
                        )}
                      />
                    </div>
                  </>
                )}
                <FormFooter submitFunc={onSubmit} disabled={isLoading} />
                {serverError && (
                  <ServerError
                    serverError={serverError}
                    id={investData?.investment?.id}
                  />
                )}
              </form>
            </>
          ) : (
            <div className="w-full pt-16 flex justify-center items-center">
              <Loader />
            </div>
          )}
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default ReitAmountScreen
