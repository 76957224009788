import { Auth } from 'aws-amplify'
import { Controller, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { ProfileHeader } from './components/profile-header'

import { useAuthDataState } from '@/routes/shared/_authDataState'
import { useEmailStore } from './api/use-email-store'
import { useToast } from '@/hooks/use-toast'

import { Button } from '@/components/ui/button'
import ErrorAlert from '@/components/ErrorAlert'
import NumberInput from '@/components/NumberInput'
import { changeEmail } from './api/change-email'
import { api, getDrupalUrl } from '@/routes/shared/_api'

export const VerifyEmail = () => {
  const { data: authData, isLoading } = useAuthDataState()
  const { toast } = useToast()
  const nav = useNavigate()
  const updatedEmail = useEmailStore((state) => state.updatedEmail)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: '',
    },
  })

  const queryClient = useQueryClient()

  const onFormSubmit = handleSubmit((data) => {
    if (!data) return
    console.log(errors, data)
    Auth.verifyCurrentUserAttributeSubmit('email', data.code)
      .then(() => {
        Auth.currentAuthenticatedUser({ bypassCache: true }).then(() => {
          toast({
            variant: 'success',
            description: 'Email verified successfully.',
            position: 'top',
          })
          nav('/dashboard/profile/email')
          queryClient.invalidateQueries(['auth'])
          queryClient.invalidateQueries(['emailVerified'])
        })
      })
      .catch((err) => {
        console.error(err.code)
        toast({
          variant: 'error',
          title: 'Error',
          description: err.message,
        })
      })
  })

  const handleReSendCode = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    changeEmail(updatedEmail)
      .then(() => {
        toast({
          variant: 'success',
          description: 'Verification code sent',
          position: 'top',
        })
      })
      .catch((err: Error) => {
        alert(err.message)
      })
  }

  const resendVerificationEmail = async () => {
    if (!updatedEmail) {
      api
        .get(
          `${getDrupalUrl()}api/v1.0/user/resend-verification?destination=${location.pathname}`
        )
        .then(() => {
          toast({
            variant: 'success',
            description: 'A verification email has been sent.',
            position: 'top',
          })
        })
    }
  }

  return (
    authData && (
      <div className="mt-10">
        {updatedEmail ? (
          <>
            <ProfileHeader subnav="Verify Email" title="Verify Email Address" />
            <div className="mb-6">
              We’ve sent a code to the email address below. Enter the code below
              to verify ownership of the email account.
            </div>
            <div className="h-9 px-4 py-2 text-base bg-gray-100 rounded justify-start items-center gap-4 inline-flex mb-6 font-medium">
              {updatedEmail}
            </div>
            <div className="max-w-[312px]">
              <form
                onSubmit={onFormSubmit}
                autoComplete="on"
                className="w-full mb-10"
              >
                {Object.keys(errors).length > 0 && (
                  <ErrorAlert text="Please fix the error(s) below:" />
                )}
                <div className="mb-6">
                  <Controller
                    name="code"
                    control={control}
                    rules={{ required: 'is required' }}
                    render={({ field, fieldState }) => (
                      <NumberInput
                        label="Enter Verification Code"
                        errorMessage={fieldState.error}
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className="text-16 mb-10">
                  Didn’t receive a code?{' '}
                  <button className="underline" onClick={handleReSendCode}>
                    Send again
                  </button>
                </div>
                <div className="flex gap-6">
                  <Button
                    variant="RM"
                    size="RM"
                    disabled={isLoading}
                    onClick={onFormSubmit}
                  >
                    Verify
                  </Button>
                  <Link to="/dashboard/profile/email">
                    <Button
                      variant="RMSecondary"
                      size="RMSecondary"
                      disabled={isLoading}
                    >
                      Cancel
                    </Button>
                  </Link>
                </div>
              </form>
            </div>
          </>
        ) : (
          <div>
            <h1 className="text-[30px] mb-6 font-bold">
              Please verify your email address.
            </h1>
            <p className="mb-6">
              To continue, please verify your email address.
            </p>
            <button
              onClick={resendVerificationEmail}
              className="flex items-center h-10 w-full md:w-fit justify-center rounded px-4 bg-primary text-16 font-medium hover:bg-primary-hover border-bg-primary text-[#F2F2F2] md:min-w-[200px]"
            >
              Send Verification Email
            </button>
          </div>
        )}
      </div>
    )
  )
}

export default VerifyEmail
