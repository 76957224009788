import { useState } from 'react'
import { Header } from '@/components/login/Header'
import { LoginForm } from '@/components/login/LoginForm'
import { MFAForm } from '@/components/login/MFAForm'

export const Login = () => {
  const [screen, setScreen] = useState<
    'login' | 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA'
  >('login')
  const [error, setError] = useState('')
  const [mfaUser, setMfaUser] = useState<any>(null)
  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState('')

  return (
    <div className="flex h-full w-full flex-col items-center">
      <Header />

      {screen === 'login' && (
        <LoginForm
          setScreen={setScreen}
          setMfaUser={setMfaUser}
          setError={setError}
          setMaskedPhoneNumber={setMaskedPhoneNumber}
          error={error}
        />
      )}

      {(screen === 'SMS_MFA' || screen === 'SOFTWARE_TOKEN_MFA') && (
        <div className="flex h-full w-full flex-col items-center">
          <MFAForm
            type={screen}
            mfaUser={mfaUser}
            setError={setError}
            error={error}
            phoneNumber={screen === 'SMS_MFA' ? maskedPhoneNumber : undefined}
          />
        </div>
      )}
    </div>
  )
}

export default Login
