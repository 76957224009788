import { Trans, useTranslation } from 'react-i18next'
import FormFooter from '../../shared/FormFooter'
import { useInvestDataState } from '../_investDataState'
import { useAuthDataState } from '../../shared/_authDataState'
import { useBankAccountsDataState } from '../_bankAccountsDataState'
import { format, parseISO } from 'date-fns'

const CompletedScreen = () => {
  const { data: investData } = useInvestDataState()
  const { data: authData } = useAuthDataState()
  const { data: bankAccountsData } = useBankAccountsDataState()
  const { t } = useTranslation()

  return (
    authData &&
    bankAccountsData && (
      <div className="h-full w-full border-t-4 border-success bg-white p-6 pb-20">
        <div className="flex gap-[72px]">
          <div className="w-full">
            <h1 className="mb-6 text-2xl font-bold text-content-black">
              <i
                className="fa-solid fa-circle-check mr-3"
                style={{ color: '#01B6A1' }}
              ></i>
              {t('completed:received')}
            </h1>
            <p className="mb-6 text-16 text-content-black">
              <span className="font-medium">
                {authData?.signInUserSession?.idToken?.payload?.given_name}
              </span>
              {t('completed:congrats')}
            </p>
            {investData.opportunity?.delayed_funding_type === 'long' &&
              investData.investment?.distribution_account_uuid &&
              !investData.investment?.funding_account_uuid &&
              investData.investment?.funding_status ===
                'wire_instructions_provided' && (
                <p className="mb-6 text-base">
                  All funds for this offering must be received by{' '}
                  {format(
                    parseISO(
                      investData.investment?.delayed_funding_payment_date
                    ),
                    'PPP'
                  )}
                  .
                </p>
              )}
            {((!investData.opportunity?.delayed_funding &&
              investData.investment?.distribution_account_uuid &&
              !investData.investment?.funding_account_uuid) ||
              (investData.opportunity?.delayed_funding &&
                investData.investment?.funding_status ===
                  'wire_instructions_provided')) && (
              <>
                <p className="mb-6 text-[19px] font-medium text-content-black">
                  Wire Instructions
                </p>
                <div className="mb-6 bg-bg-lighter p-3 text-base whitespace-pre-line prose">
                  {bankAccountsData.wire_instructions}
                </div>
              </>
            )}
            <p className="mb-6 text-[19px] font-medium text-content-black">
              {t('completed:next')}
            </p>
            {investData.opportunity.io_type === 'reit' ? (
              <ul className="mb-6 ml-3 list-disc text-base">
                {investData.investment?.distribution_account_uuid &&
                !investData.investment?.funding_account_uuid ? (
                  <>
                    <li className="mb-3">
                      {investData.opportunity?.delayed_funding &&
                      investData.investment?.funding_status ===
                        'wire_instructions_provided' ? (
                        <Trans
                          i18nKey="completed:delayedFundingWire1"
                          values={{
                            userEmail:
                              authData?.signInUserSession?.idToken?.payload
                                ?.email,
                            operationsEmail: 'operations@realtymogul.com',
                          }}
                          components={{
                            a: <a />,
                          }}
                        />
                      ) : (
                        <Trans
                          i18nKey="completed:reitWire2"
                          values={{
                            userEmail:
                              authData?.signInUserSession?.idToken?.payload
                                ?.email,
                            operationsEmail: 'operations@realtymogul.com',
                          }}
                          components={{
                            a: <a />,
                          }}
                        />
                      )}
                    </li>
                    <li className="mb-3">
                      {t('completed:reitWire3')}{' '}
                      <a className="text-primary" href="tel:+14243204205">
                        424-320-4205
                      </a>
                      .
                    </li>
                  </>
                ) : (
                  <>
                    <li className="mb-3">{t('completed:reitAch1')}</li>
                    <li className="mb-3">{t('completed:reitAch2')}</li>
                    <li className="mb-3">{t('completed:reitAch3')}</li>
                  </>
                )}
                <li>
                  {t('completed:questionsCopy')}{' '}
                  <a className="text-primary" href="tel:+14243204205">
                    424-320-4205
                  </a>{' '}
                  or email us at{' '}
                  <a
                    className="text-primary"
                    href="mailto:investor-help@realtymogul.com"
                  >
                    investor-help@realtymogul.com
                  </a>
                  .
                </li>
              </ul>
            ) : (
              <>
                {investData.opportunity?.delayed_funding_type !== 'long' ? (
                  <ul className="mb-6 ml-3 list-disc text-base">
                    {investData.investment?.distribution_account_uuid &&
                    !investData.investment?.funding_account_uuid ? (
                      <>
                        <li className="mb-3">
                          {investData.opportunity?.delayed_funding &&
                          investData.investment?.funding_status ===
                            'wire_instructions_provided' ? (
                            <Trans
                              i18nKey="completed:delayedFundingWire1"
                              values={{
                                userEmail:
                                  authData?.signInUserSession?.idToken?.payload
                                    ?.email,
                                operationsEmail: 'operations@realtymogul.com',
                              }}
                              components={{
                                a: <a />,
                              }}
                            />
                          ) : (
                            <Trans
                              i18nKey="completed:reitWire2"
                              values={{
                                userEmail:
                                  authData?.signInUserSession?.idToken?.payload
                                    ?.email,
                                operationsEmail: 'operations@realtymogul.com',
                              }}
                              components={{
                                a: <a />,
                              }}
                            />
                          )}
                        </li>
                        <li className="mb-3">
                          {t('completed:reitWire3')}{' '}
                          <a className="text-primary" href="tel:+14243204205">
                            424-320-4205
                          </a>
                          .
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="mb-3">
                          {t('completed:ach1', {
                            ioName: investData.opportunity.name,
                          })}
                        </li>
                        <li className="mb-3">{t('completed:ach2')}</li>
                      </>
                    )}

                    <li>
                      {t('completed:questionsCopy')}{' '}
                      <a className="text-primary" href="tel:+14243204205">
                        424-320-4205
                      </a>{' '}
                      or email us at{' '}
                      <a
                        className="text-primary"
                        href="mailto:investor-help@realtymogul.com"
                      >
                        investor-help@realtymogul.com
                      </a>
                      .
                    </li>
                  </ul>
                ) : (
                  <ul className="mb-6 ml-3 list-disc text-base">
                    {investData.investment?.distribution_account_uuid &&
                    !investData.investment?.funding_account_uuid ? (
                      <>
                        {investData.investment?.funding_status ===
                        'wire_instructions_provided' ? (
                          <>
                            <li className="mb-3">
                              After sending your wire transfer, we kindly ask
                              that you provide the wire reference number, also
                              known as a Federal Number, to{' '}
                              <a
                                href="mailto:operations@realtymogul.com"
                                className="text-primary"
                              >
                                operations@realtymogul.com
                              </a>{' '}
                              so that we may accurately track and confirm the
                              wire upon receipt.
                            </li>
                            <li className="mb-3">
                              PLEASE NOTE: RealtyMogul will never send wire
                              instructions directly via email unless requested
                              by you. You may validate wire instructions by
                              phone by reaching your Investor Relations
                              Representative at{' '}
                              <a
                                className="text-primary"
                                href="tel:+14243204205"
                              >
                                424-320-4205
                              </a>
                              .
                            </li>
                          </>
                        ) : (
                          <>
                            <li className="mb-3">
                              We are in the process of performing a final
                              diligence review of your investment and the
                              Sponsor of this offering is preparing to accept
                              your funds.
                            </li>
                            <li className="mb-3">
                              Upon approval of this investment and when the
                              Sponsor of this offering is ready to accept your
                              funds, we will send you an email to{' '}
                              {authData.attributes.email} with a link to access
                              wire instructions to fund your investment.
                            </li>
                            <li className="mb-3">
                              All funds for this offering must be received by{' '}
                              {format(
                                parseISO(
                                  investData.investment
                                    ?.delayed_funding_payment_date
                                ),
                                'PPP'
                              )}
                              . If this date changes in the future, you will be
                              notified by email.
                            </li>
                            <li className="mb-3">
                              You will also need to confirm that you sent the
                              wire transfer by clicking the link in the Wire
                              Instructions email. Additionally, we kindly ask
                              that you provide the wire reference number, also
                              known as a Federal Number, to{' '}
                              <a
                                href="mailto:operations@realtymogul.com"
                                className="text-primary"
                              >
                                operations@realtymogul.com
                              </a>{' '}
                              so that we may accurately track and confirm the
                              wire upon receipt.
                            </li>
                            <li className="mb-3">
                              PLEASE NOTE: RealtyMogul will never send wire
                              instructions directly via email unless requested
                              by you. You may validate wire instructions by
                              phone by reaching your Investor Relations
                              Representative at{' '}
                              <a
                                className="text-primary"
                                href="tel:+14243204205"
                              >
                                424-320-4205
                              </a>
                              .
                            </li>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <li className="mb-3">
                          We are performing final due diligence on your
                          investment and the Sponsor of this offering is
                          preparing to accept your funds. The Sponsor plans to
                          allow investors to fund via ACH on{' '}
                          {format(
                            parseISO(
                              investData.investment
                                ?.delayed_funding_payment_date
                            ),
                            'PPP'
                          )}{' '}
                          and will draft funds from your payment account on this
                          date. If this date changes in the future, you will be
                          notified by email.
                        </li>
                        <li className="mb-3">
                          Once due diligence is complete and when the Sponsor of
                          this offering is ready to accept your funds, we will
                          notify you via email prior to debiting your account
                          and again when funds have been received. Your
                          investment will then display as an active investment
                          in your dashboard.
                        </li>
                        <li className="mb-3">
                          Please allow up to 5 business days for this ACH
                          transaction to reflect in your bank account.
                        </li>
                      </>
                    )}

                    <li>
                      {t('completed:questionsCopy')}{' '}
                      <a className="text-primary" href="tel:+14243204205">
                        424-320-4205
                      </a>{' '}
                      or email us at{' '}
                      <a
                        className="text-primary"
                        href="mailto:investor-help@realtymogul.com"
                      >
                        investor-help@realtymogul.com
                      </a>
                      .
                    </li>
                  </ul>
                )}
              </>
            )}
            <FormFooter
              submitFunc={() => (window.location.href = '/dashboard')}
              secondaryLink
              buttonText="Go to Dashboard"
            />
          </div>
        </div>
      </div>
    )
  )
}

export default CompletedScreen
