import { Button } from '@/components/ui/button'
import { ProfileHeader } from '../../components/profile-header'
import { useNavigate } from 'react-router-dom'
import { SmsWidget } from './sms-widget'
import { AuthenticatorWidget } from './authenticator-widget'
import { UpdateMFASubtitle } from './update-mfa-subtitle'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '@/components/ui/dialog'
import { useState } from 'react'
// import {
//   fetchDevices,
//   forgetDevice,
//   getAccessToken,
// } from '@/routes/shared/_auth'
import { Auth } from 'aws-amplify'
import { useQueryClient } from '@tanstack/react-query'
import { useAuthDataState } from '@/routes/shared/_authDataState'

export const UpdateMFA = () => {
  const navigate = useNavigate()
  const { data } = useAuthDataState()
  const isAuthenticator = data?.preferredMFA === 'SOFTWARE_TOKEN_MFA'
  const isSMS = data?.preferredMFA === 'SMS_MFA'
  const isMfaEnabled = data?.preferredMFA !== 'NOMFA'
  const [showDisableModal, setShowDisableModal] = useState(false)
  const queryClient = useQueryClient()

  async function handleDisableMFA() {
    try {
      // const devices = await fetchDevices()
      // const accessToken = await getAccessToken()
      // await Promise.all(
      //   devices.map((device) =>
      //     forgetDevice(accessToken, device.id, queryClient)
      //   )
      // )
      await Auth.setPreferredMFA(await Auth.currentAuthenticatedUser(), 'NOMFA')
      setShowDisableModal(false)
      queryClient.invalidateQueries(['preferredMFA'])
      queryClient.invalidateQueries(['devices'])
      queryClient.invalidateQueries(['auth'])
      navigate('/dashboard/profile/email')
    } catch (error) {
      console.error('Error disabling MFA:', error)
    }
  }
  return (
    <div className="mt-10">
      <div className="max-w-xl">
        <ProfileHeader
          subnav="Two-Factor Authentication"
          title={
            isMfaEnabled
              ? 'Keep Your Account Secure'
              : 'Set Up Two-Factor Authentication'
          }
        />

        <UpdateMFASubtitle preferred_mfa={data?.preferredMFA || 'NOMFA'} />

        <div className="flex flex-col gap-4">
          {/* SMS Option */}
          <SmsWidget smsIsActive={isSMS} />
          <AuthenticatorWidget authenticatorAppIsActive={isAuthenticator} />
        </div>

        {/* Support Info */}
        {isMfaEnabled && (
          <button
            onClick={() => setShowDisableModal(true)}
            className="text-gray-600 text-sm mt-10 hover:text-gray-900 underline decoration-solid"
          >
            Disable two-factor authentication
          </button>
        )}
      </div>

      <Dialog open={showDisableModal} onOpenChange={setShowDisableModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Disable Two-Factor Authentication</DialogTitle>
          </DialogHeader>
          <DialogDescription className="py-4">
            Are you sure?
            <p className="mt-2">
              This will remove a layer of protection from your RealtyMogul
              account, but you can add it again if you choose to do so.
            </p>
          </DialogDescription>
          <div className="flex gap-4 justify-end">
            <Button className="text-white" onClick={handleDisableMFA}>
              Disable
            </Button>
            <Button
              variant="outline"
              className="text-blue-500"
              onClick={() => setShowDisableModal(false)}
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default UpdateMFA
