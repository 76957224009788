import { forwardRef } from 'react'
import { Controller, useForm } from 'react-hook-form'

import ErrorAlert from '../../../components/ErrorAlert'
import Radio from '../../../components/Radio'

type ISQFollowUpTypes = {
  followUp: {
    uuid: string
    header: string
    description: string
    label?: string
    options: { key: string; value: string }[]
  }
  disabled: boolean
  submitCallback: (data: any, shouldCancel: boolean) => Promise<void>
  closeModal: () => void
}

const ISQFollowUp = forwardRef<HTMLDialogElement, ISQFollowUpTypes>(
  (props, ref) => {
    const { followUp, closeModal, submitCallback, disabled = false } = props
    const { control, handleSubmit } = useForm()

    const onSubmit = handleSubmit(async (data) => {
      switch (Object.values(data)[0]) {
        case 'yes':
          closeModal()
          submitCallback(data, false)
          break
        case 'no':
          closeModal()
          break
        case 'cancel': {
          submitCallback(data, true)
          break
        }
        default:
          console.log('default')
      }
    })

    return (
      <dialog
        className="w-full max-w-xl flex-grow bg-white p-6 md:p-8"
        key={followUp.uuid}
        ref={ref}
      >
        <button
          className="absolute right-3 top-3 p-3.5"
          data-cy={`${followUp.uuid}-modal-close-button`}
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
        >
          <i className="fa-regular fa-xmark fa-xl"></i>
        </button>
        <p
          data-cy={`${followUp.uuid}-header`}
          className="mb-6 pr-6 text-[19px] font-medium"
        >
          {followUp.header}
        </p>
        <p data-cy={`${followUp.uuid}-content`} className="mb-6">
          {followUp.description}
        </p>
        <p className="mb-2 text-16 text-content-black">{followUp.label}</p>
        <Controller
          name={followUp.uuid}
          control={control}
          rules={{ required: 'is required' }}
          render={({ field, fieldState }) => (
            <div className="flex mb-6 flex-col gap-px">
              {fieldState.error && (
                <ErrorAlert text="Please select an answer below:" />
              )}
              {followUp.options.map((option: any) => (
                <Radio
                  {...field}
                  key={option.key}
                  value={option.key}
                  label={option.value}
                  checked={field.value === option.key}
                />
              ))}
            </div>
          )}
        />
        <button
          className="h-10 w-full rounded bg-primary-hover text-16 font-medium hover:bg-primary border-bg-primary text-[#F2F2F2]"
          data-cy={`${followUp.uuid}-modal-continue-button`}
          onClick={onSubmit}
          disabled={disabled}
        >
          Continue
        </button>
      </dialog>
    )
  }
)

export default ISQFollowUp
