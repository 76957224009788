import { useState, useEffect, useCallback } from 'react'
import { Auth } from 'aws-amplify'
import { Button } from '@/components/ui/button'
import { useToast } from '@/hooks/use-toast'
import TextInput from '@/components/TextInput'
import { useNavigate } from 'react-router-dom'
import { ProfileHeader } from '../../components/profile-header'
import { useQueryClient } from '@tanstack/react-query'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { useForm } from 'react-hook-form'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import QRCode from 'qrcode'

type FormValues = {
  verificationCode: string
}

export const AuthenticatorAppSetup = () => {
  const queryClient = useQueryClient()
  const [qrCode, setQrCode] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const { toast } = useToast()
  const navigate = useNavigate()
  const [totpSecret, setTotpSecret] = useState<string>('')
  const [username, setUsername] = useState<string>('')
  const [showDialog, setShowDialog] = useState(false)

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      verificationCode: '',
    },
  })

  const setupTOTP = useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const totpCode = await Auth.setupTOTP(user)
      setTotpSecret(totpCode)
      setUsername(user.attributes.email)
      const issuer = encodeURIComponent('RealtyMogul')
      const email = encodeURIComponent(user.attributes.email)
      const qrCodeUrl = `otpauth://totp/${issuer}:${email}?secret=${totpCode}&issuer=${issuer}`

      // Generate QR code data URL
      try {
        const dataURL = await QRCode.toDataURL(qrCodeUrl, {
          width: 150,
          margin: 2,
          color: {
            dark: '#000000',
            light: '#FFFFFF',
          },
        })
        setQrCode(dataURL)
      } catch (qrError) {
        console.error('Error generating QR code:', qrError)
      }
    } catch (error) {
      console.error('Error setting up TOTP:', error)
      toast({
        variant: 'error',
        title: 'Error',
        description: 'Failed to setup authenticator. Please try again.',
      })
    }
  }, [toast])

  // Request the QR code setup on component mount
  useEffect(() => {
    setupTOTP()
  }, [setupTOTP])

  const onSubmit = async (data: FormValues) => {
    setIsLoading(true)
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.verifyTotpToken(user, data.verificationCode)
      await Auth.setPreferredMFA(user, 'SOFTWARE_TOKEN_MFA')
      queryClient.invalidateQueries(['auth'])
      navigate('/dashboard/profile/email')
    } catch (error) {
      console.error('Error verifying TOTP:', error)
      toast({
        variant: 'error',
        title: 'Error',
        description: 'Invalid verification code. Please try again.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className="max-w-l"></div>
      <ProfileHeader
        subnav="Two-Factor Authentication"
        title="Connect your authenticator app"
      />
      <div className="space-y-8 max-w-l">
        <div>
          <h2 className="font-medium mb-2">
            1. Install an authenticator app of your choice on your mobile
            device.
          </h2>
          <Dialog open={showDialog} onOpenChange={setShowDialog}>
            <DialogTrigger asChild>
              <button className="text-blue-500 hover:underline">
                Need an authenticator app?
              </button>
            </DialogTrigger>
            <DialogContent
              aria-describedby={undefined}
              className="max-w-[600px] p-10"
            >
              <DialogTitle className="text-content-black text-left font-medium text-[22px]">
                Authenticator App Options
              </DialogTitle>
              <DialogDescription>
                <div className="text-[#202020] text-16">
                  Go to your app store and choose from popular authenticator
                  apps like Google Authenticator, Authy, Microsoft
                  Authenticator, or others.
                </div>
              </DialogDescription>
            </DialogContent>
          </Dialog>
        </div>

        <div>
          <h2 className="font-medium mb-2">
            2. Scan this QR code with your authenticator app to get a
            verification code.
          </h2>
          {qrCode && (
            <div className="w-48 h-48 bg-white p-4">
              <img src={qrCode} alt="QR Code" className="w-full h-full" />
            </div>
          )}
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <button className="text-blue-500 hover:underline mt-2">
                  Can't scan the QR code?
                </button>
              </TooltipTrigger>
              <TooltipContent className="p-6 max-w-md">
                <p className="font-medium mb-2">Manual Setup:</p>
                <p className="mb-2">
                  <span className="font-medium">Account name:</span> {username}
                </p>
                <p className="mb-2 break-all">
                  <span className="font-medium">Key:</span> {totpSecret}
                </p>
                <p className="text-sm text-gray-600">
                  Enter these details manually in your authenticator app
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        <div>
          <h2 className="font-medium mb-2">
            3. Enter the code from your authenticator app:
          </h2>
          <form onSubmit={handleSubmit(onSubmit)} className="max-w-[350px]">
            <TextInput
              name="verificationCode"
              label="Enter Code"
              placeholder="Enter 6-digit code"
              value={watch('verificationCode') || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValue('verificationCode', e.target.value)
              }}
              errorMessage={errors.verificationCode?.message}
            />

            <div className="flex gap-4 mt-6">
              <Button variant="RM" size="RM" type="submit" disabled={isLoading}>
                Verify
              </Button>
              <Button
                variant="RMSecondary"
                size="RMSecondary"
                type="button"
                onClick={() => navigate('/dashboard/profile/mfa')}
              >
                Back
              </Button>
            </div>
          </form>
        </div>
      </div>

      <div className="mt-10 text-sm text-gray-600">
        Questions? Our Investor Relations team is available to help 9 AM - 8 PM
        ET Monday to Friday. Contact us at (877) 977-2776.
      </div>
    </>
  )
}

export default AuthenticatorAppSetup
