import { InfoTooltip } from '@/routes/dashboard/components/info-tooltip'

export const UpdateMFASubtitle = ({
  preferred_mfa,
}: {
  preferred_mfa: string
}) => {
  const isAuthenticator = preferred_mfa === 'SOFTWARE_TOKEN_MFA'
  const mfaDisabled = preferred_mfa === 'NOMFA'
  if (mfaDisabled) {
    return (
      <>
        <p className="text-gray-700 mb-6">
          Add a layer of protection to your RealtyMogul account. Set up
          two-factor authentication below.{' '}
          <InfoTooltip
            target={<span className="text-blue-600 hover:underline">Why?</span>}
          >
            <>
              <div className="text-base font-medium mb-3">
                Two-factor Authentication
              </div>
              <p>
                Two-factor authentication adds an extra layer of protection to
                your account by requiring a second step to verify the person
                logging in to your account is you. This is made possible using
                one of two verification methods of your choosing - via SMS (text
                message verification) or using an Authenticator App.
              </p>
            </>
          </InfoTooltip>{' '}
        </p>
        <p className="text-gray-700 mb-4">
          Select your preferred method of two-factor authentication to continue:
        </p>
      </>
    )
  }
  return (
    <p className="text-gray-700 mb-6">
      Your account is currently protected with two-factor authentication using{' '}
      {isAuthenticator ? (
        <>
          an <strong>Authenticator App</strong>
        </>
      ) : (
        <strong>SMS verification</strong>
      )}
      .
    </p>
  )
}
