import { forwardRef } from 'react'
import { InputProps } from '../../routes/shared/_types'

export const TextInput = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const {
      label,
      helpText,
      placeholder,
      errorMessage,
      autoComplete = undefined,
    } = props
    return (
      <div className="w-full">
        {label && (
          <span
            className={`mb-2 block text-base font-normal ${
              errorMessage ? 'text-alert' : 'text-content-black'
            }`}
          >
            {label}
          </span>
        )}
        <div className="relative">
          <input
            className={`h-[42px] w-full rounded border ${
              errorMessage ? 'border-alert' : 'border-input'
            } focus:outline-none focus:ring-1 focus:border-primary px-3 py-1.5 text-16 disabled:bg-bg-lighter disabled:border-border-normal disabled:cursor-not-allowed`}
            placeholder={placeholder}
            type="text"
            ref={ref}
            name={props.name}
            onChange={props.onChange}
            onBlur={props.onBlur}
            disabled={props.disabled}
            value={props.value}
            onKeyDown={props.onKeyDown}
            autoComplete={autoComplete}
          />
          {props.disabled && (
            <div className={`absolute top-1/4 right-[12px]`}>
              <i className="fa-solid fa-lock" style={{ color: '#D5D9DD' }}></i>
            </div>
          )}
        </div>
        {errorMessage && (
          <p className="mt-1 text-xs text-alert">
            {typeof errorMessage === 'string'
              ? errorMessage
              : errorMessage.message}
          </p>
        )}
        {helpText && (
          <div className="mt-2 text-xs text-content-light">{helpText}</div>
        )}
      </div>
    )
  }
)

export default TextInput
