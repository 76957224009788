import { useState } from 'react'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@/hooks/use-toast'
import { useQueryClient } from '@tanstack/react-query'
import { useAuthDataState } from '@/routes/shared/_authDataState'
import { maskPhoneNumberCognito } from '@/routes/shared/utils'
import { useForm } from 'react-hook-form'

interface VerifyFormInputs {
  code: string
}

export const VerifyPhone = () => {
  const { toast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { data: user } = useAuthDataState()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<VerifyFormInputs>()

  const verifyCode = async (code: string) => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.verifyCurrentUserAttributeSubmit('phone_number', code)
      await Auth.setPreferredMFA(user, 'SMS_MFA')
      queryClient.invalidateQueries(['auth'])
      navigate('/dashboard/profile/email')
    } catch (error) {
      console.error('Error verifying code:', error)
      toast({
        variant: 'error',
        title: 'Error',
        description: 'Invalid verification code. Please try again.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleResendCode = async () => {
    try {
      await Auth.verifyCurrentUserAttribute('phone_number')
    } catch (error) {
      console.error('Error resending code:', error)
      // Handle error appropriately
    }
  }

  const onSubmit = async (data: VerifyFormInputs) => {
    try {
      setIsLoading(true)
      await verifyCode(data.code)
    } catch (error) {
      setError('code', {
        type: 'manual',
        message: 'Invalid verification code. Please try again.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="max-w-lg">
      <p className="text-gray-600 mb-6">
        We sent a text message with a one-time verification code to:
        <br />
        <span className="font-medium">{maskPhoneNumberCognito(user)}</span>
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6">
          <label
            htmlFor="verificationCode"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Enter Verification Code
          </label>
          <input
            id="verificationCode"
            type="text"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            required
            autoComplete="one-time-code"
            {...register('code', {
              required: 'Verification code is required',
              pattern: {
                value: /^\d{6}$/,
                message: 'Please enter a valid 6-digit code',
              },
            })}
          />
          {errors.code?.message && (
            <p className="text-red-500 text-sm mt-2">{errors.code.message}</p>
          )}
        </div>

        <div className="flex gap-4">
          <button
            type="submit"
            disabled={isLoading}
            className="bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
          >
            {isLoading ? 'Verifying...' : 'Verify'}
          </button>

          <button
            type="button"
            onClick={() => navigate(-1)}
            className="px-6 py-2 border border-blue-500 text-blue-500 rounded-md hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Back
          </button>
        </div>
      </form>
      <button
        onClick={handleResendCode}
        className="mt-4 text-blue-500 hover:text-blue-600 text-sm"
      >
        Request another code.
      </button>

      <div className="mt-8 text-sm text-gray-600">
        Questions? Our Investor Relations team is available to help 9 AM - 8 PM
        ET Monday to Friday. Contact us at (877) 977-2776.
      </div>
    </div>
  )
}
