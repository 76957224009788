import { useRef } from 'react'

interface InfoModalPropTypes {
  closeButton?: boolean
  textTarget?: React.ReactNode
  modalContent: React.ReactNode
}

export const InfoModal = (props: InfoModalPropTypes) => {
  const { closeButton = true, textTarget, modalContent } = props
  const ref = useRef<HTMLDialogElement>(null)

  return (
    <>
      {textTarget ? (
        <span
          onClick={(e) => {
            e.preventDefault()
            ref?.current?.showModal()
          }}
        >
          {textTarget}
        </span>
      ) : (
        <button
          className="w-[14px] h-[14px]"
          onClick={(e) => {
            e.preventDefault()
            ref?.current?.showModal()
          }}
        >
          <i className="w-full h-full fa-solid fa-circle-info ml-0.5 cursor-pointer text-primary" />
        </button>
      )}
      <dialog
        className="h-full w-full p-0 text-content-black xl:h-fit xl:max-w-[500px]"
        ref={ref}
        onClick={(event) => {
          if (event?.target === ref?.current) {
            ref?.current?.close()
          }
        }}
      >
        {closeButton && (
          <button
            className="absolute right-0 top-0 p-6"
            onClick={(e) => {
              e.preventDefault()
              ref?.current?.close()
            }}
          >
            <i className="fa-regular fa-xmark fa-xl"></i>
          </button>
        )}
        {modalContent}
      </dialog>
    </>
  )
}

export default InfoModal
