import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import { cn } from '@/utils/utils'
import { useAuthDataState } from '@/routes/shared/_authDataState'
import { useEmailVerifiedDataState } from '@/routes/shared/_emailVerifiedDataState'
import { useMobileNavStore } from '../api/use-mobile-nav-store'

import { EmptyState } from '../components/empty-state'
import { Loader } from '@/components/Loader'
import { ProfileItem } from './components/profile-item'
import { ProfileSubNav } from './components/profile-subnav'
import { UpdateEmail } from './components/update-email'
import { UpdatePassword } from './components/update-password'
import { VerifiedBadge } from './components/verified-badge'
import { Link } from 'react-router-dom'
import { useToast } from '@/hooks/use-toast'
import { ManageDevices } from './manage-devices'
import { useAuthDevicesState } from '@/routes/shared/_authDataState'
import { useFeatureFlags } from '@/rules/get-feature-flags'

const EmailSecurity = () => {
  const [screen, setScreen] = useState('')
  const { data, isLoading, isError } = useAuthDataState()
  const queryClient = useQueryClient()
  const { data: emailVerifiedData } = useEmailVerifiedDataState()
  const nav = useNavigate()
  const { toast } = useToast()
  const { data: devices } = useAuthDevicesState()
  const { data: featureFlagsData } = useFeatureFlags()

  const updateMobileNav = useMobileNavStore((state) => state.updateMobileNav)
  useEffect(() => {
    updateMobileNav(false)
  }, [updateMobileNav])
  const mfaEnabled = data?.preferredMFA !== 'NOMFA'
  if (screen == 'Security Settings') {
    nav('/dashboard/profile/mfa')
  }

  // Refetch on window focus
  useEffect(() => {
    const handleFocus = () => {
      queryClient.invalidateQueries(['auth'])
    }

    window.addEventListener('focus', handleFocus)
    return () => window.removeEventListener('focus', handleFocus)
  }, [queryClient])

  if (data && emailVerifiedData)
    return (
      <>
        <div
          className={cn(
            'h-10 px-6 justify-start bg-bg-lighter items-center gap-3 flex md:hidden'
          )}
          onClick={() => {
            if (screen) setScreen('')
            else {
              nav('/dashboard/profile')
            }
          }}
        >
          <button className="h-4 flex-col justify-center items-center inline-flex">
            <i className="fa-solid fa-angle-left" />
          </button>
          <div className="text-base mt-[2px]">
            {screen ? 'Email & Security' : 'Profile'}
          </div>
        </div>
        <div className="w-full h-full p-6 md:p-0">
          {screen && (
            <div className="hidden md:inline-block text-[13px] mb-10">
              <button className="text-primary" onClick={() => setScreen('')}>
                Profile
              </button>
              <i className="fa-light fa-angle-right mx-2" />
              Update {screen}
            </div>
          )}
          <h1 className="text-27 md:text-31 font-bold self-start">
            {!screen ? (
              <>
                <span className="hidden md:inline">Profile</span>
                <span className="inline md:hidden">Email & Security</span>
              </>
            ) : (
              `Update ${screen}`
            )}
          </h1>
          {!screen && (
            <div>
              <div className="hidden md:block my-10">
                <ProfileSubNav />
              </div>
              <div className="mt-6 md:mt-0 mb-10 border border-border-normal rounded">
                <div className="border-b border-bg-light">
                  <ProfileItem
                    label="Email Address"
                    value={
                      <>
                        <div className="flex items-center gap-3">
                          <div>{data.attributes.email}</div>
                          <VerifiedBadge
                            isVerified={emailVerifiedData.emailVerified}
                          />
                        </div>
                        {!emailVerifiedData.emailVerified && (
                          <div className="mt-2 font-normal">
                            Please{' '}
                            <Link
                              className="underline"
                              to="/dashboard/profile/email/verify"
                            >
                              verify your email now
                            </Link>{' '}
                            - required for investments.
                          </div>
                        )}
                      </>
                    }
                    callback={setScreen}
                  />
                </div>
                <div className="border-b border-bg-light">
                  <ProfileItem
                    label="Password"
                    value="•••••••••"
                    callback={setScreen}
                  />
                </div>
                {featureFlagsData?.ff_two_factor_auth && (
                  <>
                    <div className="border-b border-bg-light">
                      <ProfileItem
                        label="Security Settings"
                        value={
                          <>
                            <div className="flex items-center gap-3">
                              <div>Two-Factor Authentication</div>
                              <VerifiedBadge
                                isVerified={mfaEnabled}
                                verifiedText="Active"
                                notVerifiedText="Disabled"
                              />
                            </div>
                            {!mfaEnabled && (
                              <div className="mt-2 font-normal text-sm text-gray-400">
                                Add a layer of protection to your RealtyMogul
                                account.
                              </div>
                            )}
                            {data.preferredMFA === 'SOFTWARE_TOKEN_MFA' && (
                              <div className="mt-2 font-normal text-sm text-gray-400">
                                Authenticator App
                              </div>
                            )}
                            {data.preferredMFA === 'SMS_MFA' && (
                              <div className="mt-2 font-normal text-sm text-gray-400">
                                SMS
                              </div>
                            )}
                          </>
                        }
                        callback={
                          emailVerifiedData.emailVerified
                            ? setScreen
                            : () => {
                                toast({
                                  variant: 'error',
                                  description:
                                    'Please verify your email address before activating Two-Factor Authentication.',
                                  position: 'top',
                                })
                              }
                        }
                      />
                    </div>
                    <ProfileItem
                      label="Remembered Devices"
                      value={`${devices?.length} device${devices?.length === 1 ? '' : 's'}`}
                      callback={
                        devices?.length === 0 ? () => undefined : setScreen
                      }
                      noEdit={devices?.length === 0}
                    />
                  </>
                )}
              </div>
              <div className="text-base">
                To close your account, please contact{' '}
                <a
                  className="underline"
                  href="mailto:investor-help@realtymogul.com"
                >
                  RealtyMogul Investor Relations
                </a>
                .
              </div>
            </div>
          )}
          {screen === 'Email Address' && (
            <UpdateEmail
              email={data.attributes.email}
              callback={(url) => nav(url)}
              setScreen={setScreen}
            />
          )}
          {screen === 'Remembered Devices' && (
            <ManageDevices devices={devices || []} setScreen={setScreen} />
          )}
          {screen === 'Password' && <UpdatePassword callback={setScreen} />}
        </div>
      </>
    )

  if (!data)
    return (
      <div className="flex justify-center items-center w-full h-[35vh]">
        {isLoading && <Loader />}
        {isError && (
          <div>
            <EmptyState
              iconClasses="fa-regular fa-bomb fa-3x text-content-black"
              copy="There was an error while retrieving your profile settings."
              linkCopy="Browse Current Investments"
              linkTo="https://www.realtymogul.com/investment-opportunities"
            />
          </div>
        )}
      </div>
    )
}

export default EmailSecurity
