export const Header = () => {
  return (
    <div className="w-full">
      <div className="max-w-[1400px] primary bg-white px-6 py-4 mx-auto">
        <div className="flex flex-row items-center">
          <img className="mr-4" src="/rm.svg" alt="RealtyMogul logo" />
          <span className="text-content-dark text-16 font-medium uppercase tracking-[0.15em]">
            RealtyMogul
          </span>
        </div>
      </div>
    </div>
  )
}
