import { cn } from '@/utils/utils'
import { MessageType } from '../api/schema'
import { dateIn } from '@/utils/helpers'
import { mapParams } from '../../shared/map-params'

type MessageItemTypes = {
  selectedMessage?: MessageType
  item: MessageType
  handleMessageClick: (x: MessageType) => void
}

export function MessageItem({
  selectedMessage,
  item,
  handleMessageClick,
}: MessageItemTypes) {
  return (
    <div
      className={cn(
        selectedMessage?.uuid === item.uuid && 'bg-bg-lighter',
        'text-left cursor-pointer p-4 border-b-[1px] border-b-bg-light last-of-type:border-none'
      )}
      onClick={() => handleMessageClick(item)}
    >
      <div className="flex flex-col text-base gap-1 overflow-hidden">
        <div className="flex justify-between">
          <div className="font-medium h-[24px] flex items-center">
            <span>{dateIn(item.message_date)}</span>
            {item.unread && (
              <span className="px-2 py-px ml-2 text-[11px] font-medium rounded-[43px] bg-primary text-white">
                new
              </span>
            )}
          </div>
          {item.attachments?.length > 0 && (
            <div>
              <i className="fa-light fa-paperclip" />
            </div>
          )}
        </div>
        <div className="font-medium">{item.subject}</div>
        <div className="">{item.io_name}</div>
        {item.investment_account_type && (
          <div className="w-full truncate whitespace-nowrap overflow-hidden text-ellipsis">
            <span
              style={{ textTransform: 'capitalize' }}
            >{`${mapParams(item.investment_account_type ?? '')}`}</span>
            <span>{` - ${item.investment_account_name}`}</span>
          </div>
        )}
      </div>
    </div>
  )
}
