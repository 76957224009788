import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { Auth } from '@aws-amplify/auth'
import TextInput from '@/components/TextInput'
import { LoginFormTypes } from './types'

interface MFAFormProps {
  type: 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA'
  mfaUser: any
  setError: (error: string) => void
  error: string
  phoneNumber?: string
}

export const MFAForm = ({
  type,
  mfaUser,
  setError,
  error,
  phoneNumber,
}: MFAFormProps) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const queryClient = useQueryClient()

  const { control, handleSubmit } = useForm<LoginFormTypes>({
    defaultValues: {
      code: '',
      rememberDevice: false,
    },
  })

  const onSubmit = handleSubmit(async (data: LoginFormTypes) => {
    try {
      setIsSubmitting(true)
      setError('')

      await Auth.confirmSignIn(mfaUser, data.code || '', type)
      if (data.rememberDevice) {
        await Auth.rememberDevice()
      }
      queryClient.invalidateQueries({ queryKey: ['auth'] })
    } catch (error) {
      if (typeof error === 'string') {
        setError(error)
      } else if (error instanceof Error) {
        setError(error.message)
      }
    } finally {
      setIsSubmitting(false)
    }
  })

  const title =
    type === 'SMS_MFA'
      ? `We sent a text message with a one-time verification code to: ${phoneNumber || ''}`
      : 'Enter the code from your authenticator app'

  return (
    <form
      className="flex h-screen w-full flex-col items-center justify-center"
      onSubmit={onSubmit}
    >
      <h1 className="text-xl mb-6">{title}</h1>
      <div className="w-full max-w-lg p-3 space-y-6">
        <Controller
          name="code"
          control={control}
          rules={{
            required: 'Code is required',
            pattern: {
              value: /^\d{6}$/,
              message: 'Please enter a valid 6-digit code',
            },
          }}
          render={({ field, fieldState }) => (
            <TextInput
              label={
                type === 'SMS_MFA' ? 'Verification Code' : 'Authentication Code'
              }
              placeholder="Enter 6-digit code"
              errorMessage={fieldState.error?.message}
              autoComplete="one-time-code"
              {...field}
            />
          )}
        />
        <Controller
          name="rememberDevice"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={value}
                onChange={onChange}
                className="h-4 w-4 text-primary border-gray-300 rounded"
                id="remember-device"
              />
              <label htmlFor="remember-device" className="ml-2 text-sm">
                Remember this device
              </label>
            </div>
          )}
        />
        <button
          className="mt-6 mb-6 h-10 w-full rounded bg-primary-hover text-16 font-medium hover:bg-primary border-bg-primary text-[#F2F2F2] disabled:opacity-40"
          onClick={onSubmit}
          disabled={isSubmitting}
          data-cy="mfa-submit-button"
        >
          {isSubmitting ? 'Verifying...' : 'Verify'}
        </button>

        {error !== '' && (
          <div className="mt-2 rounded-full bg-bg-light px-4 py-2">
            <i className="fa-solid fa-circle-exclamation mr-2 text-alert"></i>
            {error}
          </div>
        )}
      </div>
    </form>
  )
}
