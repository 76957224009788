import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { Auth } from '@aws-amplify/auth'
import TextInput from '@/components/TextInput'
import PasswordInput from '@/components/PasswordInput'
import { loginAsUser } from '@/routes/shared/_api'
import { setTokens } from '@/utils/helpers'
import { LoginFormTypes } from './types'
import { TestUserButtons } from './TestUserButtons'

interface LoginFormProps {
  setScreen: (screen: 'login' | 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA') => void
  setMfaUser: (user: any) => void
  setError: (error: string) => void
  setMaskedPhoneNumber: (phone: string) => void
  error: string
}

export const LoginForm = ({
  setScreen,
  setMfaUser,
  setError,
  setMaskedPhoneNumber,
  error,
}: LoginFormProps) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const queryClient = useQueryClient()

  const { control, handleSubmit } = useForm<LoginFormTypes>({
    defaultValues: {
      username: '',
      password: '',
    },
  })

  const { control: drupalControl, handleSubmit: drupalSubmit } =
    useForm<LoginFormTypes>({
      defaultValues: {
        userID: '',
      },
    })

  const onSubmit = handleSubmit(async (data: LoginFormTypes) => {
    try {
      setIsSubmitting(true)
      setError('')
      const resp = await Auth.signIn(
        (data.username || '').toLowerCase(),
        data.password
      )

      if (resp.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(resp, data.password || '')
      }
      if (resp.challengeName === 'SMS_MFA') {
        setMfaUser(resp)
        setMaskedPhoneNumber(resp.challengeParam.CODE_DELIVERY_DESTINATION)
        setScreen('SMS_MFA')
      }
      if (resp.challengeName === 'SOFTWARE_TOKEN_MFA') {
        setMfaUser(resp)
        setScreen('SOFTWARE_TOKEN_MFA')
      }
    } catch (error) {
      if (typeof error === 'string') {
        setError(error)
      } else if (error instanceof Error) {
        setError(error.message)
      }
    } finally {
      queryClient.invalidateQueries({ queryKey: ['auth'] })
      setIsSubmitting(false)
    }
  })

  const onDrupalSubmit = drupalSubmit(async (data: LoginFormTypes) => {
    if (!data.userID) return
    try {
      setIsSubmitting(true)
      setError('')
      const responseData = await loginAsUser(data.userID)
      setTokens(
        responseData.auth.AccessToken,
        responseData.auth.IdToken,
        responseData.auth.RefreshToken
      )
    } catch (error) {
      if (typeof error === 'string') {
        setError(error)
      } else if (error instanceof Error) {
        setError(error.message)
      }
    } finally {
      queryClient.invalidateQueries({ queryKey: ['auth'] })
      setIsSubmitting(false)
    }
  })

  return (
    <form
      className="flex h-screen w-full flex-col items-center justify-center"
      onSubmit={onSubmit}
    >
      <h1 className="text-xl mb-6">
        Please log in again to securely access RealtyMogul.
      </h1>
      <div className="w-full max-w-lg p-3 space-y-6">
        <Controller
          name="username"
          control={control}
          rules={{
            required: 'is required',
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Email is invalid',
            },
          }}
          render={({ field, fieldState }) => (
            <TextInput
              label="Email"
              errorMessage={fieldState.error}
              {...field}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          rules={{ required: 'is required' }}
          render={({ field, fieldState }) => (
            <PasswordInput
              label="Password"
              errorMessage={fieldState.error}
              {...field}
            />
          )}
        />
        <button
          className="mt-6 mb-6 h-10 w-full rounded bg-primary-hover text-16 font-medium hover:bg-primary border-bg-primary text-[#F2F2F2] disabled:opacity-40"
          onClick={onSubmit}
          disabled={isSubmitting}
          data-cy="login-button"
        >
          Sign In
        </button>

        {/* Test User Buttons - Only show in non-production */}
        {window.location.origin !== 'https://my.realtymogul.com' && (
          <TestUserButtons
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
            drupalControl={drupalControl}
            onDrupalSubmit={onDrupalSubmit}
            queryClient={queryClient}
          />
        )}

        {error !== '' && (
          <div className="mt-2 rounded-full bg-bg-light px-4 py-2">
            <i className="fa-solid fa-circle-exclamation mr-2 text-alert"></i>
            {error}
          </div>
        )}
      </div>
    </form>
  )
}
