import { useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { cancelInvestment } from '../../shared/_api'

import { useAccountDataState } from '../_accountDataState'
import { usePersonalInfoDataState } from '../_personalInfoDataState'
import { useInvestDataState } from '../_investDataState'
import { STATES } from '../../../utils/helpers'
import { track } from '../../../utils/analytics'

const StateFailedScreen = () => {
  const { data: investData } = useInvestDataState()
  const { data: accountData } = useAccountDataState()
  const { data: personalInfoData } = usePersonalInfoDataState()
  const { t } = useTranslation()

  const stateName = STATES.find((item: any) => {
    switch (accountData?.current_account?.type) {
      case 'joint':
        return (
          accountData?.current_account?.primary.address.state === item.value
        )
      case 'individual':
        return personalInfoData?.address?.state === item.value
      default:
        return (
          accountData?.current_account?.jurisdiction_of_registration ===
          item.value
        )
    }
  })?.label

  useEffect(() => {
    if (!accountData) return
    track('Cancel Investment')
    cancelInvestment(investData?.investment?.id)
  }, [accountData, investData])

  return (
    accountData && (
      <div className="w-full flex flex-row gap-[72px]">
        <div
          data-cy="state-failed-screen"
          className="h-full w-full border-t-4 border-alert-light bg-white p-6 pb-20"
        >
          <p className="mb-6 text-16 font-medium text-content-black">
            {t('failed:stateHeader')}
          </p>
          <p className="mb-6 text-base text-content-black">
            <Trans
              i18nKey="failed:stateCopy"
              values={{
                state: stateName,
                ioName: investData?.opportunity.name,
              }}
            />{' '}
            <a
              className="text-primary"
              href="mailto:investor-help@realtymogul.com"
            >
              investor-help@realtymogul.com
            </a>
            .
          </p>
          <a
            className="flex h-10 w-full md:w-fit px-4 items-center justify-center rounded bg-primary-hover text-16 font-medium hover:bg-primary border-bg-primary text-[#F2F2F2] disabled:opacity-50"
            data-cy="continue-button"
            href="/dashboard"
          >
            {t('common:seeOtherOpps')}
          </a>
        </div>
        <div className="hidden w-[400px] xl:block"></div>
      </div>
    )
  )
}

export default StateFailedScreen
