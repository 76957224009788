import { Link } from 'react-router-dom'

/**
 * ProfileHeader component renders a navigation breadcrumb and page title for profile pages
 *
 * @param subnav - The current subsection name to display in the breadcrumb navigation
 * @param title - The main heading text to display on the page
 * @returns JSX element containing the breadcrumb navigation and page title
 */
export const ProfileHeader = ({
  subnav,
  title,
}: {
  subnav: string
  title: string
}) => {
  return (
    <>
      <div className="inline-block text-[13px] mb-10">
        <Link className="text-primary" to="/dashboard/profile">
          Profile
        </Link>
        <i className="fa-light fa-angle-right mx-2" />
        {subnav}
      </div>
      <h1 className="text-27 md:text-31 font-bold self-start mb-10">{title}</h1>
    </>
  )
}
