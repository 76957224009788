export const funding = {
  fundYourInvestment: `Fund Your Investment`,
  fundingCopy: `
    As a final step, please provide the bank account you would like to use to fund your investment. All information and transactions are encrypted and RealtyMogul does not know or store your bank account login credentials.
    <br /><br />
    Please note: Your bank account used for this investment must match your ownership type selected earlier (ie. individual, trust, LLC, etc.)`,
  selectBankAccount: `Distribution Account`,
  noBankAccounts: `No bank accounts found.  Please add a bank account
  below.`,
  addBankAccount: `Add a Bank Account`,
  connectBankAccount: `Connect Your Bank Account`,
  plaidTrusted: `We use our trusted partner, Plaid, the industry standard for
  connecting your bank account as a funding source.`,
  connectToBank: `Connect to Bank`,
  connectToPlaid: `Connecting to Plaid...`,
  manualCopy: `<strong>If you are unable to connect or locate your bank,
  </strong> or do not wish to provide your bank login, you can `,
  manualBankAccount: `Manual Bank Account Entry`,
  manualBankAccountCopy: `Please provide the following information for the bank account you would like to use for this investment.`,
  voidedCheckLater: `I will provide a copy of a voided check later. I acknowledge that my investment will not be processed until I provide the
  voided check.`,
  wireTransfer: `Wire Transfer`,
  wireTransferCopy: `Please follow the instructions below to submit your wire
  transfer.`,
  amountToWire: `Amount to Wire`,
  wireInstructions: `Wire Instructions`,
  sameBankAccount: `Please note: Your bank account used for this investment must match your ownership type selected earlier (ie. individual, trust, LLC, etc.)`,
  important: `Important`,
  confirmation: `Confirmation`,
  afterWireInstructions: `After submitting your wire transfer, please email the wire reference number (or Federal Number) to the address below so we can accurately track and confirm your wire transfer.`,
  confirmOrAdd: `Select the account to receive distributions for this investment.`,
  distributionAccount: `Distribution Account`,
  orAddNew: `Or, Add a New Account`,
}
