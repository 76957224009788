import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { CustomCognitoUser } from './_types'
export const getPhoneNumber = (phoneNumber: string) => {
  if (isValidPhoneNumber(phoneNumber)) {
    return formatPhoneNumber(phoneNumber)
  }
  return phoneNumber
}
export const maskPhoneNumber = (phoneNumber: string) => {
  const maskedPhoneNumber = `(***) ***-**${phoneNumber?.slice(-2)}` || ''
  return maskedPhoneNumber
}

export const maskPhoneNumberCognito = (
  cogUser: CustomCognitoUser | undefined
) => {
  if (!cogUser) return ''
  return maskPhoneNumber(cogUser.attributes.phone_number)
}
