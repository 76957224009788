import { useEffect, useState } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'

import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { useTaxesFilters } from './api/get-tax-filters'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'

import { Pagination } from './components/pagination'
import { Filter } from './components/filter'
import { columns } from './components/columns'
import { EmptyState } from '../components/empty-state'
import { useTaxDocuments } from './api/get-tax-documents'
import Loader from '@/components/Loader'
import { mapParams } from '../shared/map-params'
import { hasSpecificFilters } from '../shared/has-specific-filters'
import { cn } from '@/utils/utils'
import { InvestmentsTooltip } from './components/investments-tooltip'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Skeleton } from '@/components/ui/skeleton'

const TaxCenter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [showFilters, setShowFilters] = useState(false)
  const { data, isError, isLoading, isFetching } = useTaxDocuments()
  const { data: filtersData } = useTaxesFilters()
  const { pathname, search } = useLocation()

  const [pagination, setPagination] = useState({
    pageIndex: Math.max(Number(searchParams.get('page') || 0) - 1, 0),
    pageSize: 10,
  })

  useEffect(() => {
    const page = parseInt(searchParams.get('page') ?? '0') || 1
    setPagination((pagination) => {
      return { ...pagination, pageIndex: page - 1 }
    })
  }, [searchParams])

  const table = useReactTable({
    data: data?.documents ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const handleFilterUpdate = (name: string, uuid: string) => {
    const paramName = mapParams(name)
    const nextSearchParams = new URLSearchParams(searchParams)
    uuid === 'all'
      ? nextSearchParams.delete(paramName)
      : nextSearchParams.set(
          paramName,
          paramName === 'document_type' ? uuid : mapParams(uuid)
        )
    setSearchParams(nextSearchParams)
    setPagination({ ...pagination, pageIndex: 0 })
  }

  return data ? (
    <div className="w-full h-full">
      <div className="w-full md:mb-10 flex justify-between items-center p-6 md:p-0 relative">
        <h1 className="text-27 md:text-31 font-bold self-start">Tax Center</h1>
        <Dialog open={showFilters} onOpenChange={setShowFilters}>
          <DialogTrigger asChild>
            <div className="md:w-1/2 justify-end flex flex-wrap flex-row gap-2 md:hidden">
              <button
                className={cn(
                  'w-10 h-10 text-[20px] md:hidden',
                  hasSpecificFilters([
                    'tax_years',
                    'io_uuid',
                    'investment_account_uuid',
                    'document_types',
                  ]) && 'text-primary'
                )}
                onClick={() => setShowFilters(true)}
              >
                <i className="fa-light fa-sliders" />
              </button>
            </div>
          </DialogTrigger>
          <DialogContent
            aria-describedby={undefined}
            className="top-72 w-[90%] rounded"
          >
            <div className="flex flex-col gap-4 overflow-hidden">
              <DialogTitle className="text-content-light font-normal">
                Filter Transactions
              </DialogTitle>
              {filtersData &&
                Object.entries(filtersData)
                  .sort((a, b) => {
                    const order = ['tax_years', 'document_types', 'investments']
                    const indexA = order.indexOf(a[0])
                    const indexB = order.indexOf(b[0])

                    if (indexA === -1 && indexB === -1) return 0
                    if (indexA === -1) return 1
                    if (indexB === -1) return -1
                    return indexA - indexB
                  })
                  .map((entry) => {
                    return (
                      <Filter
                        key={entry[0]}
                        name={
                          entry[0] === 'investment_accounts'
                            ? 'ownership_types'
                            : entry[0]
                        }
                        data={Array.isArray(entry[1]) ? entry[1] : [entry[1]]}
                        updateCallback={handleFilterUpdate}
                        defaultValue={searchParams.get(
                          entry[0] === 'investment_accounts'
                            ? 'investment_account_uuid'
                            : mapParams(entry[0])
                        )}
                      />
                    )
                  })}
            </div>
          </DialogContent>
        </Dialog>
        <div className="hidden md:flex justify-end flex-row gap-2 flex-wrap">
          {filtersData &&
            Object.entries(filtersData)
              .sort((a, b) => {
                const order = ['tax_years', 'document_types', 'investments']
                const indexA = order.indexOf(a[0])
                const indexB = order.indexOf(b[0])

                if (indexA === -1 && indexB === -1) return 0
                if (indexA === -1) return 1
                if (indexB === -1) return -1
                return indexA - indexB
              })
              .map((entry) => {
                return (
                  <Filter
                    key={entry[0]}
                    name={
                      entry[0] === 'investment_accounts'
                        ? 'ownership_types'
                        : entry[0]
                    }
                    data={Array.isArray(entry[1]) ? entry[1] : [entry[1]]}
                    updateCallback={handleFilterUpdate}
                    defaultValue={searchParams.get(
                      entry[0] === 'investment_accounts'
                        ? 'investment_account_uuid'
                        : mapParams(entry[0])
                    )}
                  />
                )
              })}
        </div>
      </div>

      {data.count > 0 && (
        <div className="px-6 md:px-0 mb-6">
          {data.count.toLocaleString()} documents
        </div>
      )}
      {data.documents.length ||
      hasSpecificFilters([
        'tax_years',
        'io_uuid',
        'investment_account_uuid',
        'document_type',
      ]) ? (
        <div className="mb-10">
          <div className="md:hidden px-6">
            {data.documents.length > 0 ? (
              <div>
                {data.documents.map((document) => (
                  <div
                    key={document.uuid}
                    className={cn(
                      'p-3 border border-t-0 first-of-type:border-t border-border-normal first-of-type:rounded-t last-of-type:border-b-0 text-base flex flex-row text-content-black',
                      !document.document_url &&
                        'text-content-light pointer-events-none'
                    )}
                  >
                    <div className="mr-4 font-medium">{document.tax_year}</div>
                    <div className="flex-grow flex flex-col gap-1">
                      <div className="font-medium">
                        {mapParams(document.form_type)}
                      </div>
                      <div>
                        {document.investments.length > 1 ? (
                          <InvestmentsTooltip
                            isUploaded={document.document_url || ''}
                            rowInvestmentsData={document.investments}
                          />
                        ) : (
                          <Link
                            className="underline cursor-pointer text-base"
                            to={`/dashboard/investments/details/${document.investments[0].io_uuid}`}
                            state={{ previousLocation: pathname + search }}
                          >
                            {document.investments[0].io_name}
                          </Link>
                        )}
                      </div>
                      <div>{document.investment_account_name}</div>
                    </div>
                    <div
                      onClick={() => {
                        if (!document?.document_url) {
                          return
                        }
                        window.open(document?.document_url)
                      }}
                      className="w-8 h-8 text-16 flex justify-center items-center"
                    >
                      <i
                        className={cn(
                          'fa-light',
                          document.document_url
                            ? 'fa-download text-primary'
                            : 'fa-info-circle'
                        )}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="p-3 border border-t-0 first-of-type:border-t border-border-normal first-of-type:rounded-t ">
                No results.
                {hasSpecificFilters([
                  'transaction_type',
                  'io_uuid',
                  'investment_account_uuid',
                  'date_from',
                  'date_to',
                ]) && (
                  <span>
                    {' '}
                    -{' '}
                    <span
                      className="underline"
                      onClick={() => {
                        const nextSearchParams = new URLSearchParams({
                          page: '1',
                        })
                        setPagination({
                          ...pagination,
                          pageIndex: 0,
                          pageSize: 5,
                        })
                        setSearchParams(nextSearchParams)
                      }}
                    >
                      reset all filters
                    </span>
                  </span>
                )}
              </div>
            )}
            <div className="border border-border-normal rounded-b border-t-0">
              <Pagination
                pagination={pagination}
                totalPages={data.pages}
                totalCount={data.count}
                changePage={(newPage) => {
                  const newSearchParams = new URLSearchParams(searchParams)
                  newSearchParams.set('page', (newPage + 1).toString())
                  setSearchParams(newSearchParams)
                }}
              />
            </div>
          </div>
          <div className="hidden md:block rounded-md border overflow-hidden">
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableHead>
                      )
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  <>
                    {!isFetching ? (
                      table.getRowModel().rows.map((row) => (
                        <TableRow
                          key={row.id}
                          data-state={row.getIsSelected() && 'selected'}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <TableCell key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    ) : (
                      <>
                        {[...Array(pagination.pageSize)].map(() => (
                          <TableRow key={Math.random()}>
                            <TableCell className="h-[75px] text-center">
                              <Skeleton className="w-full h-3" />
                            </TableCell>
                            <TableCell className="h-[75px] text-center">
                              <Skeleton className="w-full h-3" />
                            </TableCell>
                            <TableCell className="h-[75px] text-center">
                              <Skeleton className="w-full h-3" />
                            </TableCell>
                            <TableCell className="h-[75px] text-center">
                              <Skeleton className="w-full h-3" />
                            </TableCell>
                            <TableCell className="h-[75px] text-center">
                              <Skeleton className="w-full h-3" />
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      No results.
                      {hasSpecificFilters([
                        'transaction_type',
                        'io_uuid',
                        'investment_account_uuid',
                        'date_from',
                        'date_to',
                      ]) && (
                        <span>
                          {' '}
                          -{' '}
                          <span
                            className="underline cursor-pointer"
                            onClick={() => {
                              const nextSearchParams = new URLSearchParams({
                                page: '1',
                              })
                              setPagination({
                                ...pagination,
                                pageIndex: 0,
                                pageSize: 5,
                              })
                              setSearchParams(nextSearchParams)
                            }}
                          >
                            reset all filters
                          </span>
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Pagination
              pagination={pagination}
              totalPages={data.pages}
              totalCount={data.count}
              changePage={(newPage) => {
                const newSearchParams = new URLSearchParams(searchParams)
                newSearchParams.set('page', (newPage + 1).toString())
                setSearchParams(newSearchParams)
              }}
            />
          </div>
        </div>
      ) : (
        <div className="md:py-40">
          {!isLoading && !isFetching && data ? (
            <EmptyState
              iconClasses="fa-regular fa-files fa-3x text-primary opacity-20"
              copy="You have no documents."
              linkCopy="Browse Current Investments"
              linkTo="https://www.realtymogul.com/investment-opportunities"
            />
          ) : (
            <Loader />
          )}
        </div>
      )}
    </div>
  ) : (
    <div className="flex justify-center items-center w-full h-[35vh]">
      {isLoading && <Loader />}
      {isError && (
        <EmptyState
          iconClasses="fa-regular fa-bomb fa-3x text-content-black"
          copy="There was an error while retrieving your transactions."
          linkCopy="Browse Current Investments"
          linkTo="https://www.realtymogul.com/investment-opportunities"
        />
      )}
    </div>
  )
}

export default TaxCenter
