import { useState } from 'react'
import { IAuthDevice } from '@aws-amplify/auth/lib-esm/types'
import { useToast } from '@/hooks/use-toast'
import { Button } from '@/components/ui/button'
import { useQueryClient } from '@tanstack/react-query'
import { forgetDevice } from '@/routes/shared/_auth'
import { Auth } from 'aws-amplify'
interface ManageDevicesProps {
  devices: IAuthDevice[]
  setScreen: (screen: string) => void
}

export const ManageDevices = ({ devices, setScreen }: ManageDevicesProps) => {
  const [loading, setLoading] = useState<string | null>(null)
  const { toast } = useToast()
  const queryClient = useQueryClient()
  const getDeviceIcon = (device: IAuthDevice) => {
    const deviceName = device.name?.toLowerCase() || ''

    if (deviceName.includes('iphone') || deviceName.includes('ios')) {
      return 'fa-solid fa-mobile-screen-button'
    }
    if (deviceName.includes('android')) {
      return 'fa-brands fa-android'
    }
    if (deviceName.includes('ipad')) {
      return 'fa-solid fa-tablet-screen-button'
    }
    if (deviceName.includes('mac')) {
      return 'fa-brands fa-apple'
    }
    if (deviceName.includes('windows')) {
      return 'fa-brands fa-windows'
    }
    if (deviceName.includes('linux')) {
      return 'fa-brands fa-linux'
    }
    // Default device icon
    return 'fa-solid fa-desktop'
  }

  const handleForgetDevice = async (device: IAuthDevice) => {
    try {
      setLoading(device.id)
      const session = await Auth.currentSession()
      const accessToken = session.getAccessToken()
      const jwtToken = accessToken.getJwtToken()
      const deviceKey = accessToken.payload.device_key
      await forgetDevice(jwtToken, device.id, queryClient)
      if (deviceKey === device.id) {
        await Auth.signOut()
        window.location.href =
          'https://www.realtymogul.com/investment-opportunity'
      }
      //was causing front end to feeze
      // toast({
      //   position: 'top',
      //   description: 'Device removed successfully',
      //   variant: 'success',
      // })
      // Return to the main screen to refresh the devices list
      setScreen('')
    } catch (error) {
      toast({
        position: 'top',
        description: 'Failed to remove device',
        variant: 'error',
      })
    } finally {
      setLoading(null)
    }
  }

  return (
    <div className="max-w-2xl pt-4">
      <table className="w-full">
        <tbody className="divide-y">
          {devices.map((device) => (
            <tr key={device.id} className="hover:bg-gray-50">
              <td className="py-3 px-4">
                <div className="flex items-center justify-center">
                  <i
                    className={`${getDeviceIcon(device)} text-lg text-gray-600`}
                  />
                </div>
              </td>
              <td className="py-3 px-4">
                <div>{device.name || 'Unknown Device'}</div>
                <div className="text-sm text-gray-500"></div>
              </td>
              <td className="py-3 px-4 text-right">
                <Button
                  onClick={() => handleForgetDevice(device)}
                  disabled={loading === device.id}
                  variant="RM"
                  size="default"
                >
                  {loading === device.id ? (
                    <i className="fa-solid fa-spinner fa-spin" />
                  ) : (
                    'Forget Device'
                  )}
                </Button>
              </td>
            </tr>
          ))}
          {devices.length === 0 && (
            <tr>
              <td colSpan={3} className="text-center py-8 text-gray-500">
                No devices found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
