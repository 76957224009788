import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import parse from 'html-react-parser'

import { useMobileNavStore } from '../../api/use-mobile-nav-store'

import { ScrollArea } from '@/components/ui/scroll-area'
import { MessageType } from '../api/schema'
import { dateIn } from '@/utils/helpers'
import { mapParams } from '../../shared/map-params'

type SelectedMessageAreaTypes = {
  selectedMessage: MessageType
  unselectMessage?: () => void
}

export function SelectedMessageArea({
  selectedMessage,
  unselectMessage = () => {
    return
  },
}: SelectedMessageAreaTypes) {
  const updateMobileNav = useMobileNavStore((state) => state.updateMobileNav)
  const nav = useNavigate()

  useEffect(() => updateMobileNav(false), [updateMobileNav])

  return (
    <>
      <div
        className=" h-10 px-6 justify-start bg-bg-lighter items-center gap-3 flex md:hidden"
        onClick={() => {
          updateMobileNav(true)
          unselectMessage()
        }}
      >
        <button className="h-4 flex-col justify-center items-center inline-flex">
          <i className="fa-solid fa-angle-left" />
        </button>
        <div className="text-base mt-[2px]">Messages</div>
      </div>
      <div className="hidden md:flex flex-col overflow-y-auto">
        <div className="w-full flex justify-between border-b bg-bg-lighter text-base px-4 py-3 gap-3 font-medium">
          <span>{selectedMessage.subject}</span>
          <span className="whitespace-pre font-normal">
            {dateIn(selectedMessage.message_date)}
          </span>
        </div>
        <div className="p-4 flex flex-col gap-1 text-base border-b border-bg-light">
          {selectedMessage.io_name && (
            <div className="flex items-center">
              <div className="w-32 text-content-light text-sm">Investment</div>
              <div
                className="underline cursor-pointer"
                onClick={() =>
                  nav(
                    `/dashboard/investments/details/${selectedMessage.io_uuid}`
                  )
                }
              >
                {selectedMessage.io_name}
              </div>
            </div>
          )}
          {selectedMessage.investment_account_type && (
            <div className="flex items-center">
              <div className="w-32 text-content-light text-sm">
                Ownership Type
              </div>
              <div>
                <span
                  style={{ textTransform: 'capitalize' }}
                >{`${mapParams(selectedMessage.investment_account_type ?? '')}`}</span>
                {` - ${selectedMessage.investment_account_name}`}
              </div>
            </div>
          )}
        </div>
        {selectedMessage.attachments.length > 0 && (
          <div className="p-4 flex flex-col gap-1 text-base border-b border-bg-light">
            <div className="flex items-center">
              <div className="w-32 text-content-light text-sm">Attachments</div>
              <div className="flex-1 flex flex-col gap-1">
                {selectedMessage.attachments.map((attachment) => (
                  <a
                    key={attachment.file_name}
                    href={attachment.document_url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-content-black cursor-pointer hover:text-primary-hover"
                  >
                    <i className="fa-regular fa-paperclip text-primary mr-2" />
                    <span>{attachment.file_name}</span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        )}
        <ScrollArea className="w-full reset-parsed-text px-10 py-8 text-base">
          {parse(selectedMessage.body)}
          {selectedMessage.io_uuid && (
            <div className="text-sm text-content-light italic">
              The above presentation is based upon information supplied by the
              Sponsor. Realty Mogul, Co., and RM Manager, LLC along with their
              respective affiliates, officers, directors or representatives (the
              "RM Parties") hereby advise you that none of them has
              independently confirmed or verified any of the information
              contained herein. The RM Parties further make no representations
              as to the accuracy or completeness of any such information and
              undertake no obligation now or in the future to update or correct
              this presentation or any information contained herein.
            </div>
          )}
        </ScrollArea>
      </div>
      <div className="md:hidden p-6 min-h-[75vh]">
        <div className="whitespace-pre font-normal mb-4">
          {selectedMessage.message_date}
        </div>
        <div className="font-medium text-17 mb-6">
          {selectedMessage.subject}
        </div>
        <div className="text-content-light text-sm mb-2">Investment</div>
        <div className="text-base mb-4">{selectedMessage.io_name}</div>
        <div className="text-content-light text-sm mb-2">Ownership Type</div>
        <div className="text-base mb-2">
          <span
            style={{ textTransform: 'capitalize' }}
          >{`${selectedMessage.investment_account_type}`}</span>
          {` - ${selectedMessage.investment_account_name}`}
        </div>
        <div className="reset-parsed-text py-4 border-t-[1px] border-t-bg-light">
          {parse(selectedMessage.body)}
          <div className="text-sm text-content-light italic">
            The above presentation is based upon information supplied by the
            Sponsor. Realty Mogul, Co., and RM Manager, LLC along with their
            respective affiliates, officers, directors or representatives (the
            "RM Parties") hereby advise you that none of them has independently
            confirmed or verified any of the information contained herein. The
            RM Parties further make no representations as to the accuracy or
            completeness of any such information and undertake no obligation now
            or in the future to update or correct this presentation or any
            information contained herein.
          </div>
        </div>
        {selectedMessage.attachments.length > 0 && (
          <div className="flex flex-col gap-1 text-base border-b border-bg-light">
            <div className="mb-6 font-medium text-xl">Attachments</div>
            <div className="flex flex-col gap-3">
              {selectedMessage.attachments.map((attachment) => (
                <a
                  key={attachment.file_name}
                  href={attachment.document_url}
                  target="_blank"
                  rel="noreferrer"
                  className="border border-bg-light px-3 py-2 flex flex-row gap-3"
                >
                  <div className="text-primary">
                    <i className="fa-light fa-paperclip" />
                  </div>
                  <div className="text-base">{attachment.file_name}</div>
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
