import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getIO } from '../shared/_api'

export const useInvestDataState = () => {
  const id = window.location.pathname.split('/')[2]
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: ['invest'],
    queryFn: async () => {
      const data = await getIO(id)
      const overrideData = queryClient.getQueryData(['overrideData']) || {}
      return {
        ...data,
        ...overrideData,
      }
    },
    enabled: !!id && window.location.pathname.includes('invest/'),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })
}
