import { z } from 'zod'

export const associatedInvestmentSchema = z.object({
  investment_account_name: z.string().nullable(),
  investment_account_type: z.string().nullable(),
  start_date: z.string().nullable(),
  io_name: z.string(),
  io_uuid: z.string().uuid(),
  investment_uuid: z.string().uuid(),
  investment_status: z.string(),
  investment_account_uuid: z.string().uuid(),
  image: z.string().nullable(),
  bank_account_uuid: z.string().uuid().nullable(),
  bank_account_description: z.string().nullable(),
  bank_account_name: z.string().nullable(),
  bank_account_type: z.string().nullable(),
  bank_account_status: z.string().nullable(),
  mask: z.string().nullable(),
})

export type AssociatedInvestmentTypes = z.infer<
  typeof associatedInvestmentSchema
>

export const associatedInvestmentsSchema = z.array(associatedInvestmentSchema)

export type AssociatedInvestmentsTypes = z.infer<
  typeof associatedInvestmentsSchema
>

export const bankAccountSchema = z.object({
  account_name: z.string().nullable(),
  account_description: z.string().nullable(),
  account_type: z.enum(['checking', 'savings']),
  account_status: z.enum(['active', 'inactive', 'deleted']),
  validation_status: z.enum([
    'valid',
    'invalid',
    'grandfathered',
    'no',
    'rejected',
    'submitted_manual',
    'validated_manual',
    'validated_plaid',
    'validated_yodlee',
  ]),
  uuid: z.string().uuid(),
  mask: z.string().nullable(),
  is_funding_in_progress_on_investment: z.boolean(),
  number_of_associated_distribution_investments: z.number().int(),
  removal_allowed: z.boolean(),
})

export type BankAccountTypes = z.infer<typeof bankAccountSchema>

export const distributionAccountSchema = z.object({
  account_name: z.string().nullable(),
  account_description: z.string().nullable(),
  account_type: z.enum(['checking', 'savings']),
  account_status: z.enum(['active', 'inactive', 'deleted']),
  validation_status: z.enum([
    'valid',
    'invalid',
    'grandfathered',
    'no',
    'rejected',
    'submitted_manual',
    'validated_manual',
    'validated_plaid',
    'validated_yodlee',
  ]),
  uuid: z.string().uuid(),
  mask: z.string().nullable(),
})

export type DistributionAccountTypes = z.infer<typeof distributionAccountSchema>

export const bankAccountsSchema = z
  .array(
    z
      .object({
        investment_account_name: z.string().nullish(),
        investment_account_uuid: z.string().uuid().nullish(),
        investment_account_type: z.string().nullish(),
        bank_accounts: z.array(bankAccountSchema),
      })
      .optional()
  )
  .optional()

export type BankAccountsTypes = z.infer<typeof bankAccountsSchema>

export const patchInvestmentSchema = z.object({
  uuid: z.string().uuid(),
  distribution_account_uuid: z.string().uuid(),
})

export type PatchInvestmentTypes = z.infer<typeof patchInvestmentSchema>
