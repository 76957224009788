import { cn } from '@/utils/utils'

export const VerifiedBadge = ({
  isVerified,
  verifiedText = 'Verified',
  notVerifiedText = 'Not verified',
}: {
  isVerified: boolean
  verifiedText?: string
  notVerifiedText?: string
}) => {
  return (
    <div
      className={cn(
        'flex justify-center items-center rounded-[45px] px-2 py-[2px] h-[20px] text-[11px]',
        isVerified
          ? 'bg-[#E6F5F0] text-[#008E7D]'
          : 'bg-[#FFF3B9] text-[#826600]'
      )}
    >
      <span>
        <i
          className={cn(
            'mr-1',
            isVerified ? 'fa-regular fa-check' : 'fa-regular fa-info-circle'
          )}
        />
      </span>
      <span className="font-medium">
        {isVerified ? verifiedText : notVerifiedText}
      </span>
    </div>
  )
}
