import { Control, UseFormHandleSubmit } from 'react-hook-form'
import { QueryClient } from '@tanstack/react-query'
import { Controller } from 'react-hook-form'
import NumberInput from '@/components/NumberInput'
import {
  createAndLoginTestUser,
  createAndLoginUnverifiedTestUser,
} from '@/routes/shared/_api'
import { LoginFormTypes } from './types'

interface TestUserButtonsProps {
  isSubmitting: boolean
  setIsSubmitting: (value: boolean) => void
  drupalControl: Control<LoginFormTypes>
  onDrupalSubmit: ReturnType<UseFormHandleSubmit<LoginFormTypes>>
  queryClient: QueryClient
}

export const TestUserButtons = ({
  isSubmitting,
  setIsSubmitting,
  drupalControl,
  onDrupalSubmit,
  queryClient,
}: TestUserButtonsProps) => {
  return (
    <>
      <div className="flex justify-center mb-4">- OR -</div>
      <button
        className="h-10 w-full rounded bg-primary-hover text-16 font-medium hover:bg-primary border-bg-primary text-[#F2F2F2] disabled:opacity-40"
        disabled={isSubmitting}
        onClick={(e) => {
          e.preventDefault()
          setIsSubmitting(true)
          createAndLoginTestUser()
            .then(() => queryClient.invalidateQueries({ queryKey: ['auth'] }))
            .finally(() => setIsSubmitting(false))
        }}
      >
        {isSubmitting ? (
          <span className="fa-lg block mr-4">
            <i className="fa-spin fa-solid fa-spinner-third mr-4"></i>
            Creating user...
          </span>
        ) : (
          'Create and Log In as Test User'
        )}
      </button>
      <div className="flex justify-center mb-4">- OR -</div>
      <button
        className="h-10 w-full rounded bg-primary-hover text-16 font-medium hover:bg-primary border-bg-primary text-[#F2F2F2] disabled:opacity-40"
        disabled={isSubmitting}
        onClick={(e) => {
          e.preventDefault()
          createAndLoginUnverifiedTestUser()
            .then(() => queryClient.invalidateQueries({ queryKey: ['auth'] }))
            .finally(() => setIsSubmitting(false))
        }}
      >
        Create and Log In as Test User (Unverified)
      </button>
      <div className="flex justify-center mb-4">- OR -</div>
      <Controller
        name="userID"
        control={drupalControl}
        rules={{ required: 'is required' }}
        render={({ field }) => <NumberInput label="User ID" {...field} />}
      />
      <button
        className="mt-6 mb-6 h-10 w-full rounded bg-primary-hover text-16 font-medium hover:bg-primary border-bg-primary text-[#F2F2F2] disabled:opacity-40"
        onClick={onDrupalSubmit}
        disabled={isSubmitting}
      >
        Login with Drupal User ID #
      </button>
    </>
  )
}
