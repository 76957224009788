import { Auth, CookieStorage } from '@aws-amplify/auth'
import {
  CognitoIdentityProviderClient,
  ForgetDeviceCommand,
} from '@aws-sdk/client-cognito-identity-provider'
import { QueryClient } from '@tanstack/react-query'
declare global {
  interface Window {
    Cypress?: Cypress.Cypress
  }
}

export const cookieStorage = new CookieStorage({
  domain: (() => {
    if (window.location.origin.includes('rtml.localhost')) {
      return '.rtml.localhost'
    }
    if (window.location.origin.includes('localhost')) {
      return '.localhost'
    }
    return '.realtymogul.com'
  })(),
  path: '/',
  expires: 1,
  sameSite: 'strict',
  secure: !window.location.origin.includes('localhost'),
})

export const awsConfig = {
  Auth: {
    region: 'us-west-2',
    userPoolId:
      window.location.origin === 'https://my.realtymogul.com'
        ? `us-west-2_Z1cMSCXWl`
        : `us-west-2_iOmHSc6io`,
    userPoolWebClientId:
      window.location.origin === 'https://my.realtymogul.com'
        ? `715inci7q6uq2kpv3fdv9opknj`
        : `2iivupb66i0h6ng72dt0im61gp`,
    storage: cookieStorage,
  },
}

export const getUser = async () => {
  const cogUser = await Auth.currentAuthenticatedUser()
  return cogUser
}

export const cognitoLogout = () => {
  Auth.signOut()
}

export const fetchDevices = async () => {
  const devices = await Auth.fetchDevices()
  return devices
}

export const getAccessToken = async () => {
  const session = await Auth.currentSession()
  const accessToken = session.getAccessToken().getJwtToken()
  return accessToken
}

export const forgetDevice = async (
  accessToken: string,
  deviceKey: string,
  queryClient: QueryClient
) => {
  console.log(accessToken)
  try {
    const client = new CognitoIdentityProviderClient({
      region: 'us-west-2',
      // Add your credentials configuration here if needed
    })

    const command = new ForgetDeviceCommand({
      AccessToken: accessToken,
      DeviceKey: deviceKey,
    })

    await client.send(command)
    queryClient.invalidateQueries(['devices'])
    return true
  } catch (err) {
    console.log('some thing went wrong', err)
    throw err
  }
}
